import React, { useEffect } from "react";

const FAQSchemaflorida = () => {
  useEffect(() => {
    // Create the script element
    const script = document.createElement("script");
    script.type = "application/ld+json";
    script.text = JSON.stringify({
      "@context": "https://schema.org",
      "@type": "FAQPage",
      mainEntity: [
        {
          "@type": "Question",
          name: "What Can I Expect with Costs and Insurance?",
          acceptedAnswer: {
            "@type": "Answer",
            text: `Sober living homes vary in price. Monthly costs usually cover rent, utilities, and shared expenses. Some homes accept insurance, while many require private payment.`,
          },
        },
        {
          "@type": "Question",
          name: "What is the Average Duration of Stay?",
          acceptedAnswer: {
            "@type": "Answer",
            text: `There's no set timeline for your stay. Most residents stay three to twelve months, depending on their needs. The goal is to leave feeling confident and prepared for independence.`,
          },
        },
      ],
    });

    // Append the script to the document head
    document.head.appendChild(script);

    // Cleanup to remove the script when the component unmounts
    return () => {
      document.head.removeChild(script);
    };
  }, []);

  return null;
};

export default FAQSchemaflorida;
