import React, { Component } from "react";
import { connect, useSelector } from "react-redux";
import { authAction } from "../../actions";
import backImgBlack from "../../assets/images/back.svg";
import { Field, Form, Formik, ErrorMessage } from "formik";
import { Toast, Button } from "react-bootstrap";
import { SpinnerContext } from "../../components/spinner/spinner.component";
import moment from "moment";
import * as Yup from "yup";
import { Modal } from "react-bootstrap";
import { isEmpty } from "lodash";
import LocationSearchInput from "../../components/map/location-search-input.component";
import { countryList } from "../auth/countryList";
import tickSaveImg from "../../assets/images/tick-save.svg";
import rightArrowImg from "../../assets/images/arrow_right-24px.svg";
class EditProfile extends Component {
  static contextType = SpinnerContext;
  errorPopUpStyle = {
    position: "fixed",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "auto",
    color: "#FF5F5F",
    alignItems: "center",
  };
  formatPhoneNumber = (input) => {
    if (!input) return "";

    const phoneNumber = input.replace(/\D/g, "");

    if (!phoneNumber) return "";

    let formattedPhoneNumber = `(${phoneNumber.slice(0, 3)})`;

    if (input.length < formattedPhoneNumber.length) {
      formattedPhoneNumber = formattedPhoneNumber.slice(0, -1);
      return formattedPhoneNumber;
    }

    if (phoneNumber.length > 3) {
      formattedPhoneNumber += `-${phoneNumber.slice(3, 6)}`;
    }
    if (phoneNumber.length > 6) {
      formattedPhoneNumber += `-${phoneNumber.slice(6, 10)}`;
    }
    if (phoneNumber.length > 10) {
      formattedPhoneNumber += `-${phoneNumber.slice(10, 15)}`;
    }

    return formattedPhoneNumber;
  };

  constructor(props) {
    super(props);
    this.state = {
      editMode: false,
      errors: [],
      showError: false,
      username: "",
      firstName: "",
      lastName: "",
      company: "",
      phoneNumber: this.formatPhoneNumber(props?.me?.phone) || "",
      maskedPhone: this.formatPhoneNumber(props?.me?.phone) || "",
      country: "",
      searchTerm: this.props.me?.country || "",
      showErrorChangePassword: false,
      changePasswordMode: false,
      showErrorPopup: false,
      showSuccessPopup: false,
      errors: null,
    };
  }

  componentDidMount() {
    this.props.getUser();
    if (!this.props.isFetchingMe) {
      this.props.getUser();

      const { me } = this.props;
      if (me && me.phone) {
        const formattedPhoneNumber = this.formatPhoneNumber(me.phone);
        this.setState({ maskedPhone: formattedPhoneNumber });
      }
    }
  }
  handleToggleDropdown = () => {
    this.setState((prevState) => ({
      isOpen: !prevState.isOpen,
    }));
  };

  handleInputChange = (e) => {
    const input = e.target.value;
    const numericValue = input.replace(/\D/g, "");
    const formattedValue = this.formatPhoneNumber(input);
    this.setState({ maskedPhone: formattedValue, phoneNumber: numericValue });
  };

  handleOnChange = (e) => {
    this.setState({
      searchTerm: e.target.value,
    });
  };
  changePasswordSchema = Yup.object().shape({
    currentPassword: Yup.string()
      .min(6, "Old Password should be equal and bigger than 6 symbols")
      .required("Please enter your old password"),
    newPassword: Yup.string()
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*])[A-Za-z\d!@#$%^&*]{8,}$/,
        "New password should be minimum 8 characters long and should contain atleast one uppercase, one lowercase, one numeric and one special symbol"
      )
      .required("Password is required"),
  });

  editFormSchema = Yup.object().shape({
    name: Yup.string()
      .trim()
      .required("Username is required")
      .min(6, "Username should be equal or bigger than 6 symbols")
      .max(50, "Username must be less than or equal to 50 characters")
      .matches(
        /^[A-Za-z0-9 ]*$/,
        "Username must be alphanumeric and must not contain special characters"
      ),

    firstName: Yup.string()
      .required("First name is required")
      .max(50, "First name must be less than or equal to 50 characters")
      .matches(
        /^[A-Za-z0-9 ]*$/,
        "First name must be alphanumeric and must not contain special characters"
      ),

    lastName: Yup.string()
      .required("Last name is required")
      .max(50, "Last name must be less than or equal to 50 characters")
      .matches(
        /^[A-Za-z0-9 ]*$/,
        "Last name must be alphanumeric and must not contain special characters"
      ),

    phoneNumber: this.props.isHost ? Yup.string() : Yup.string().notRequired(),

    city: Yup.string(),
    email: Yup.string()
      .required("Email is required")
      .email("Please enter valid email"),
  });
  editForm = ({ values, errors, handleSubmit, setFieldValue }) => {
    const handleSubmitLocal = (e) => {
      if (!isEmpty(errors)) {
        this.setState({ showErrorPopup: true, errors: errors });
        setTimeout(() => {
          this.setState({ showErrorPopup: false });
        }, 2500);
      }
    };


    return (
      <Form onSubmit={handleSubmit} autoComplete="off">
        <div className="form-group">
          <label htmlFor="">
            Name (User Name) <span className="mandatory">*</span>
          </label>
          <Field
            component="input"
            name="name"
            value={values.name}
            className="form-control mt-2"
            placeholder="Name"
          />
        </div>
        <div className="form-group">
          <label htmlFor="">
            First Name <span className="mandatory">*</span>
          </label>
          <Field
            component="input"
            name="firstName"
            value={values.firstName}
            className="form-control mt-2"
            placeholder="First name"
          />
        </div>
        <div className="form-group">
          <label htmlFor="">
            Last Name <span className="mandatory">*</span>
          </label>
          <Field
            component="input"
            name="lastName"
            value={values.lastName}
            className="form-control mt-2"
            placeholder="Last name"
          />
        </div>
        {this.props.isHost ? (
          <div className="form-group">
            <label htmlFor="">Company Name</label>
            <Field
              component="input"
              name="company"
              value={values.company}
              className="form-control mt-2"
              placeholder="Company name"
            />
          </div>
        ) : (
          ""
        )}
        {this.props.isHost && (
          <div className="form-group">
            <label htmlFor="">
              Phone <span className="mandatory">*</span>
            </label>
            <Field
              type="text"
              style={{ cursor: "pointer" }}
              name="phoneNumber"
              onChange={this.handleInputChange}
              value={this.state.maskedPhone}
              placeholder="Phone"
              className="form-control"
              maxLength={20}
            />
          </div>
        )}
        <div className="form-group">
          <label htmlFor="">
            City, State <span className="mandatory">*</span>
          </label>
          <LocationSearchInput
            placeholder="City State"
            dropdownStyle="city-dropdown"
            value={`${values.city}${values.state ? `, ${values.state}` : ""}`}
            onCitySelect={(res) => {
              const [city, state, country] = res.cityName.split(", ");

              this.setState({
                searchTerm: country ? country : state,
              });
              setFieldValue("city", `${city}, ${state}`, true);
            }}
          />
        </div>
        <div className="form-group">
          <label htmlFor="">
            Country <span className="mandatory">*</span>
          </label>
          <input
            type="text"
            name="country"
            value={this.state.searchTerm}
            readOnly={this.state.searchTerm ? true : false}
            className="form-control mt-2"
            placeholder="Country"
            onClick={this.handleToggleDropdown}
            onChange={this.handleOnChange}
          />
        </div>

        <div className="form-group">
          <label htmlFor="">Bio</label>
          <Field
            component="textarea"
            name="bio"
            value={values.bio}
            className="form-control mt-2"
            placeholder="Tell us a little bit about yourself and your sober livings!"
          />
        </div>
        <div className="form-group">
          <label htmlFor="">
            Email <span className="mandatory">*</span>
          </label>
          <Field
            component="input"
            name="email"
            style={{ cursor: "pointer" }}
            value={values.email}
            className="form-control mt-2"
            placeholder="Email"
            readOnly={true}
          />
        </div>
        <div
          className="form-group"
          onClick={() => {
            this.setState({ changePasswordMode: true });
          }}
        >
          <label htmlFor="">Change Password</label>
          <div className="change-password d-flex align-items-center justify-content-between">
            <span>.........</span>
            <span>
              <img src={rightArrowImg} alt="right-arrow" />
            </span>
          </div>
        </div>
        <Toast
          onClose={() => this.setState({ showError: false })}
          show={this.state.showError}
          className="list-group-item-warning"
        >
          <Toast.Body>
            <ul>
              {this.state.showError &&
                this.state.errors?.map(function (item) {
                  return <li key={item}>{item.split(":")[1] || item}</li>;
                })}
            </ul>
          </Toast.Body>
        </Toast>

        <button
          type="submit"
          onClick={(e) => {
            handleSubmitLocal(e);
          }}
          className="btn btn-primary border-radius-20"
        >
          <img src={tickSaveImg} alt="save-btn" style={{ width: "18px" }} />
          Save
        </button>
      </Form>
    );
  };

  changePasswordForm = ({
    values,
    errors,
    handleChange,
    handleSubmit,
    isSubmitting,
  }) => {
    const handleSubmitLocal = (e) => {
      e.preventDefault();

      if (!values.newPassword && !values.currentPassword) {
        this.setState({
          showErrorPopup: true,
          errors: { newPassword: "Please enter your new and old password" },
        });
        setTimeout(() => {
          this.setState({ showErrorPopup: false });
        }, 5000);
        return;
      }

      if (!isEmpty(errors)) {
        this.setState({ showErrorPopup: true, errors: errors });
        setTimeout(() => {
          this.setState({ showErrorPopup: false });
        }, 5000);
        return;
      }

      handleSubmit(e);
    };
    return (
      <Form
        className="taste-recovery-form"
        onSubmit={handleSubmit}
        autoComplete="off"
      >
        <div className="form-group">
          <label htmlFor="">Old password</label>
          <Field
            type="password"
            value={values.currentPassword}
            autoComplete="new-password"
            name="currentPassword"
            className="form-control mt-2"
            placeholder="Old Password"
          />
        </div>
        <div className="form-group">
          <label htmlFor="">New password</label>
          <Field
            type="password"
            name="newPassword"
            value={values.newPassword}
            className="form-control mt-2"
            placeholder="New Password"
          />
        </div>

        <Toast
          onClose={() => this.setState({ showErrorChangePassword: false })}
          show={this.state.showErrorChangePassword}
          className="list-group-item-warning"
        >
          <Toast.Body>
            <ul>
              {this.state.showErrorChangePassword &&
                this.state.errors?.map(function (item) {
                  return <li key={item}>{item.split(":")[1] || item}</li>;
                })}
            </ul>
          </Toast.Body>
        </Toast>

        <button
          type="submit"
          onClick={(e) => {
            handleSubmitLocal(e);
          }}
          className="btn btn-primary border-radius-20"
        >
          <img src={tickSaveImg} alt="save-btn" style={{ width: "18px" }} />
          Save
        </button>
      </Form>
    );
  };

  submit = (data, resetForm) => {
    const { phoneNumber } = this.state;

    if (!phoneNumber && this.props.isHost) {
      this.setState({
        errors: ["Phone number is required"],
        showError: true,
      });
      return;
    }
    if (!phoneNumber && this.props.isHost) {
      this.setState({
        errors: ["Please select phone number"],
        showError: true,
      });
      setTimeout(() => {
        this.setState({ showError: false });
      }, 4000);
      return;
    }

    if (
      (phoneNumber.length < 10 || phoneNumber.length > 20) &&
      this.props.isHost
    ) {
      this.setState({
        errors: ["Phone number must be between 10 and 15 digits"],
        showError: true,
      });
      setTimeout(() => {
        this.setState({ showError: false });
      }, 4000);
      return;
    }

    const payload = { ...data };
    const [city, state] = payload.city.split(", ");

    payload.city = city ? city : payload.city;
    payload.state = state ? state : payload.state;
    payload.country = this.state.searchTerm;
    payload.phone = this.state.phoneNumber;

    const userInfo = { ...payload };
    this.setState({
      errors: [],
      showError: false,
      showErrorChangePassword: false,
    });
    this.context.showLoader("Updating user info....");
    this.props
      .updateUser(userInfo)
      .then((res) => {
        this.setState({ editMode: false });
        resetForm({});
      })
      .catch((err) => {
        this.setState({ errors: err.data, showError: true });
      })
      .finally(() => {
        this.context.hideLoader();
      });
  };

  changePassword = (data, resetForm) => {
    this.setState({
      errors: [],
      showError: false,
      showErrorChangePassword: false,
    });
    this.context.showLoader("Updating password....");
    this.props
      .changePassword(data)
      .then((res) => {
        this.setState({ showSuccessPopup: true, changePasswordMode: false });
        setTimeout(() => {
          this.setState({ showSuccessPopup: false });
        }, 2000);
        setTimeout(() => {
          this.props.logout();
        }, 2500);
        resetForm({});
      })
      .catch((err) => {
        this.setState({ errors: err.data, showErrorChangePassword: true });
      })
      .finally(() => {
        this.context.hideLoader();
      });
  };

  render() {
    const { me, isHost } = this.props;
    const {
      editMode,
      changePasswordMode,
      showErrorPopup,
      showSuccessPopup,
      errors,
    } = this.state;
    return (
      <div className="savehome-wrp setting-list active" id="five">
        {showSuccessPopup && (
          <Modal
            centered
            show={true}
            style={this.errorPopUpStyle}
            className="spinner-taste"
            onHide={() => {}}
          >
            <img
              src={require("../../assets/images/check.png")}
              width="50"
              heigth="50"
              alt="Error!"
            />
            <span className="loading-msg">
              For changing password please visit email, and do follow
              instruction
            </span>
          </Modal>
        )}

        {showErrorPopup && (
          <Modal
            centered
            show={true}
            style={this.errorPopUpStyle}
            className="spinner-taste"
            onHide={() => {}}
          >
            <img
              src={require("../../assets/images/cross-cancel.png")}
              width="50"
              heigth="50"
              alt="Error!"
            />
            <span className="loading-msg">
              {errors[Object.keys(errors)[0]]}
            </span>
          </Modal>
        )}

        {editMode && !changePasswordMode ? (
          <div className="edit-profile-view taste-recovery-form mt-4">
            <div className={"mb-4"}>
              <a
                onClick={() => {
                  if (changePasswordMode && editMode) {
                    this.setState({
                      changePasswordMode: false,
                      editMode: true,
                    });
                  } else if (editMode) {
                    this.setState({ editMode: false });
                  } else {
                    this.setState({ editMode: false });
                  }
                }}
              >
                <img src={backImgBlack} alt="back-btn" />
              </a>
            </div>
          </div>
        ) : changePasswordMode ? (
          <div className="edit-profile-view taste-recovery-form mt-4">
            <div className={"mb-4"}>
              <a
                onClick={() => {
                  this.setState({ changePasswordMode: false });
                }}
              >
                <img src={backImgBlack} alt="back-btn" />
              </a>
            </div>
          </div>
        ) : (
          " "
        )}

        <h3 className="main-title">
          {changePasswordMode ? "Change Password" : "Edit Profile"}
        </h3>
        {editMode && !changePasswordMode ? (
          <div className="edit-profile-view taste-recovery-form mt-4">
            <Formik
              initialValues={{
                name: me.name,
                city: `${me.city}`,
                state: me.state,
                bio: me.bio,
                email: me.email,
                firstName: me?.firstName,
                lastName: me?.lastName,
                company: me?.company,
                phoneNumber: me?.phone,
                country: me?.country,
                bio: me?.bio,
              }}
              enableReinitialize
              validateOnMount={true}
              validationSchema={this.editFormSchema}
              validator={() => {}}
              onSubmit={(values, { setSubmitting, resetForm }) => {
                this.submit(values, resetForm);
              }}
            >
              {(props) => this.editForm(props)}
            </Formik>
          </div>
        ) : changePasswordMode ? (
          <div>
            <Formik
              enableReinitialize
              validateOnMount={true}
              initialValues={{
                currentPassword: "",
                newPassword: "",
              }}
              validationSchema={this.changePasswordSchema}
              validator={() => {
                console.log("in vlaidator");
              }}
              onSubmit={(values, { setSubmitting, resetForm }) => {
                this.changePassword(values, resetForm)
                  .then((res) => {
                    this.props.logout();
                  })
                  .catch((error) => {
                    console.error("Error while changing password:", error);
                  });
              }}
            >
              {(props) => this.changePasswordForm(props)}
            </Formik>
          </div>
        ) : (
          <div className="edit-profile mt-4" id="editProfile">
            <p className="text-muted mb-3 text-center text-md-left">
              Joined in {moment(me.createdAt).format("YYYY") || ""}
            </p>
            <div className="form-group">
              <label htmlFor="">Name (User name)</label>
              <span>{me.name}</span>
            </div>
            <div className="form-group">
              <label htmlFor="">First Name </label>
              <span>{me.firstName}</span>
            </div>
            <div className="form-group">
              <label htmlFor="">Last Name </label>
              <span>{me.lastName}</span>
            </div>
            {isHost === true && (
              <div className="form-group">
                <label htmlFor="">Company Name </label>
                <span>{me.company}</span>
              </div>
            )}
            {isHost === true && (
              <div className="form-group">
                <label htmlFor="">Phone Number </label>
                <span style={{ cursor: "pointer" }}>
                  {this.formatPhoneNumber(me.phone) || "1234567891"}
                </span>
              </div>
            )}
            <div className="form-group">
              <label htmlFor="">City, State</label>
              <span>
                {me.city} {me.state ? "," : ""} {me.state}
              </span>
            </div>
            <div className="form-group">
              <label htmlFor="">Country </label>
              <span>{me.country}</span>
            </div>
            <div className="form-group">
              <label htmlFor="">Bio</label>
              <span>{me.bio || "Tasterecovery testing "}</span>
            </div>
            <div className="form-group">
              <label htmlFor="">Email</label>
              <span style={{ cursor: "pointer" }}>{me.email}</span>
            </div>
            <div
              className="form-group"
              style={{ width: "35%" }}
              onClick={() => {
                this.setState({ changePasswordMode: true });
              }}
            >
              <label htmlFor="">Change Password</label>
              <div className="change-password d-flex align-items-center justify-content-between">
                <span>.........</span>
                <span>
                  <img src={rightArrowImg} alt="right-arrow" />
                </span>
              </div>
            </div>
            <Button
              className="btn btn-primary border-radius-20"
              onClick={() => {
                this.setState({ editMode: true });
              }}
            >
              Edit
            </Button>
          </div>
        )}
      </div>
    );
  }
}

export default connect(
  ({ auth }) => ({
    isFetchingMe: auth.isFetchingMe,
    me: auth.me,
    isUpdatingMe: auth.isUpdatingMe,
    isHost: auth?.isHost,
  }),
  authAction
)(EditProfile);
