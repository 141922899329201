import React, { useState } from "react";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
const Sobercontentfaq = () => {
  const [expandedIndex, setExpandedIndex] = useState(null);

  const toggleAccordion = (index) => {
    setExpandedIndex(expandedIndex === index ? null : index); // Close if it's already expanded, else open
  };

  const faqData = [
    {
      question: "What kinds of house features and programs are available?",
      answer: (
        <>
          <p>
            While every house offers slightly different programs, there is a
            standard that all locations aim for. All of the support offered at
            sober living houses focuses on thorough and reliable recovery. You
            should expect a fully clean and sober living environment, with
            activities and camaraderie to support it.
          </p>
        </>
      ),
    },
    {
      question:
        "What are the house rules at the men’s or women’s sober houses?",
      answer: (
        <>
          <p className="mb-2">
            Each location will have slightly different rules, but there is one
            big one. Each house operates as a clean, substance-free setting so
            that all residents can progress according to their goals. When you
            check into a location, your house manager will be able to provide
            detailed house rules for you.
          </p>

          <p>
            Residents at a sober living home fill out a very detailed house
            rules contract when signing up. This contract covers all the living
            styles and preferences that will be in accordance with house rules.
            As such, these rules and requirements are listed on a home’s listing
            page. Before deciding on a sober living home for yourself or for a
            loved one, we recommend reviewing these forms. You can then
            determine which house rules you will best be able to follow before
            moving in.
          </p>
        </>
      ),
    },

    {
      question: "How can I get into a location?",
      answer: (
        <>
          <p className="mb-2">
            Once you’ve identified a sober home that works for you, simply reach
            out to the admissions team. They'll help you get up to speed on
            pricing, move-in schedules, and more.
          </p>
          <p>
            Each location has different contact details, with some homes being
            more accessible than others. When in doubt, reference the contact
            details that are available on our website.
          </p>
        </>
      ),
    },
  ];

  return (
    <div className="container">
      <div className="faq-content">
        <div className="faq-content-block">
          <h2 className="faqs">Frequently Asked Questions</h2>
          <p>Here are the questions commonly asked about house tours:</p>
        </div>

        {faqData.map((faq, index) => (
          <div key={index} className="faq-content-block accordion-block">
            <div
              className={`accordion-header ${
                expandedIndex === index ? "expanded" : ""
              }`}
              onClick={() => toggleAccordion(index)}
            >
              <h3>{faq.question}</h3>
              <span className="icon"></span>
            </div>
            {expandedIndex === index && (
              <div className="accordion-content">
                <div className="inner-content">{faq.answer}</div>
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default Sobercontentfaq;
