import React from "react";

const Residetialheader = () => {
  return (
    <div className="container">
      <div className="faq-content py-0">
        <div className="faq-content-block py-4 mt-2">
          {/* <h1 className="faqs mb-0 text-center">
            Residential Treatment Centers
          </h1> */}
        </div>
      </div>
    </div>
  );
};

export default Residetialheader;
