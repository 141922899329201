import React, { useState, useEffect, useContext } from "react";
import backImgBlack from "../../assets/images/back.svg";
import { useSelector, useDispatch } from "react-redux";
import { SERVER_APIS } from "../../constants";
import { Modal } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import {
  detoxAction,
  livingAction,
  paymentActions,
  residentialAction,
} from "../../actions/index";
import { SpinnerContext } from "../../components/spinner/spinner.component";
import axios from "axios";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";

const ResidentialSubscription = ({ info }) => {
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();
  const state = useSelector((state) => state?.residential);

  const { setsubscribePlanId, setsubscribePlanType } =
    useContext(SpinnerContext);
  const { showLoader, hideLoader } = useContext(SpinnerContext);
  const couponData = useSelector((state) => state?.payment?.couponData);
  const couponerror = useSelector((state) => state?.payment?.error);
  const [showSubscriptionBasic, setShowSubscriptionBasic] = useState(false);
  const [showSubscriptionPro, setShowSubscriptionPro] = useState(false);
  const [basicDetails, setBasicDetails] = useState([]);
  const [isBasic, setIsBasic] = useState(false);
  const [isPro, setIsPro] = useState(false);
  const [couponCode, setCouponCode] = useState("");
  const [recurringPeriod, setRecurringPeriod] = useState("monthly");
  const [priceAmount, setPriceAmount] = useState(basicDetails[0]?.unit_amount);
  const [priceId, setPriceId] = useState(basicDetails[0]?.id);
  const [isOpen, setIsOpen] = useState(false);
  const [isUpgrade, setIsUpgrade] = useState(false);
  const [upgradeMessage, setUpgradeMessage] = useState("");
  const [showErrorPopup, setShowErrorPopup] = useState(false);
  const [couponSuccess, setCouponSuccess] = useState(false);
  const [couponReject, setCouponReject] = useState(false);
  const [confirmationPop, setconfirmationPop] = useState(false);
  const [contentdata, setcontentdata] = useState("");
  useEffect(() => {
    dispatch(residentialAction.getResidentialSubscriptionPlan());
  }, []);

  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "unset";
    }
    return () => {
      document.body.style.overflow = "unset";
    };
  }, [isOpen]);

  useEffect(() => {
    setPriceAmount(basicDetails[0]?.unit_amount);
    setPriceId(basicDetails[0]?.id);
    return () => {
      setRecurringPeriod("monthly");
      setCouponCode("");
    };
  }, [basicDetails]);

  const handleNormalSubs = (type) => {
    setIsOpen(true);
    if (type === "pro") {
      setIsPro(true);
      setIsBasic(false);
    } else if (type === "basic") {
      setIsBasic(true);
      setIsPro(false);
    }
    setsubscribePlanId(500);
    setsubscribePlanType("facility");
    dispatch(residentialAction.setResidentialFacilityType(info));
    const basicDetails = state?.residentialPlan?.filter(
      (item) => item.planType === type
    );
    setBasicDetails(basicDetails);
  };

  const handleApplyCoupon = async () => {
    try {
      const data = {
        priceId: priceId,
        couponCode: couponCode?.trim(),
        facilityType: location?.state?.state?.facilityType
          ? location?.state?.state?.facilityType
          : state?.facilityInfo?.facilitytype,
      };
      const inputElement = document.querySelector('input[name="applyCoupon"]');
      await dispatch(paymentActions.disccountCouponapplayaction(data));
      setShowErrorPopup(true);
      setCouponSuccess(true);
      setTimeout(() => {
        setShowErrorPopup(false);
        setCouponSuccess(false);
        if (inputElement) inputElement.focus();
      }, 2500);
    } catch {
      setShowErrorPopup(true);
      setCouponReject(true);
      setTimeout(() => {
        setShowErrorPopup(false);
        setCouponReject(false);
        setCouponCode("");
        const inputElement = document.querySelector(
          'input[name="applyCoupon"]'
        );
        if (inputElement) inputElement.focus();
      }, 2500);
    }
  };

  const upgradSubsciptionFunc = async () => {
    const updatedServerApi = {
      ...SERVER_APIS,
      facilityCenter: `/${location?.state?.state?.facilityType}`,
    };
    const valueFacility = {
      subscriptionId: location?.state?.state?.subscriptionId,
      userId: location?.state?.state?.userId,
      couponCode: couponCode,
      newPriceId: priceId,
    };

    var facilityOption = {
      method: "post",
      url: `${updatedServerApi.facilityCenter}/${location?.state?.state?.facilityId}/upgrade/subscription`,
      data: valueFacility,
      headers: {
        "Content-Type": "application/json",
      },
    };

    await axios(facilityOption)
      .then(async (res) => {
        setUpgradeMessage(
          res?.data?.message ? res?.data.message : res?.data?.error
        );
        setIsUpgrade(true);
        hideLoader("");
        setTimeout(() => {
          setIsUpgrade(false);
          if (res?.data?.message) {
            history.push("/profile");
          } else {
            setIsBasic(false);
            setIsPro(false);
          }
        }, 2500);
      })
      .catch((error) => {
        alert(error);
      });
    await dispatch(livingAction.fetchhostLivings());
  };

  const paySubscription = () => {
    if (location?.state?.state?.isUpgrade) {
      showLoader("Upgrading facility center");
      setIsOpen(false);
      upgradSubsciptionFunc();
    } else {
      history.push("/payment-options", {
        state: {
          name: info.name,
          priceId: priceId,
          priceAmount: couponData?.discountedPrice
            ? couponData?.discountedPrice
            : priceAmount,
          recurringPeriod: recurringPeriod,
          facilityType: "residential",
          subsFlow: "normal",
          couponCode: couponCode,
        },
      });
    }
    dispatch(paymentActions.resetCouponData());
    setconfirmationPop(false);
  };
  const getMeassageforUpgrade = async () => {
    const data = {
      subscriptionId: location?.state?.state?.subscriptionId, // Use shorthand property names
      newPriceId: priceId,
    };
    const getData = await dispatch(
      detoxAction.fetchMessageForSubscription(data)
    );
    setcontentdata(getData);
    if (getData?.error) {
      setShowErrorPopup(true);
    } else {
      setconfirmationPop(true);
    }

    setTimeout(() => {
      setShowErrorPopup(false);
    }, 2000);
  };

  return (
    <>
      {showErrorPopup && (
        <Modal
          centered
          show={showErrorPopup}
          onHide={() => {}}
          className="upgrade-plan cancel-subscription-modal updated-successfully-modal"
          style={{ background: "transparent" }}
          backdrop={false}
        >
          <div className="upgrade-plan-content d-flex flex-column align-items-center">
            {couponSuccess && (
              <span className="loading-msg">
                {"Discount coupon applied successfully."}
              </span>
            )}
            {couponReject && (
              <span className="loading-msg">{couponerror?.data[0]}</span>
            )}

            {contentdata?.error && contentdata?.error}
          </div>
        </Modal>
      )}

      {confirmationPop && (
        <div
          className="modal upgrade-plan cancel-subscription-modal fade show change-subscription"
          id="exampleModal"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content">
              <div className="upgrade-plan-content">
                <h3>Subscription Change Confirmation?</h3>
                <span className="loading-msg">{contentdata?.message}</span>
              </div>
              <div className="modal-footer">
                <button
                  type="submit"
                  className="modal-btn"
                  onClick={() => setconfirmationPop(false)}
                >
                  {contentdata?.error ? "Cancel" : "No"}
                </button>
                {contentdata?.error ? (
                  ""
                ) : (
                  <button
                    type="submit"
                    className="modal-btn confirm-btn"
                    onClick={() => paySubscription()}
                  >
                    Yes
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      )}

      {isUpgrade && (
        <Modal
          centered
          show={setUpgradeMessage}
          onHide={() => {}}
          className="upgrade-plan cancel-subscription-modal updated-successfully-modal"
          style={{ background: "transparent" }}
        >
          <div className="upgrade-plan-content d-flex flex-column align-items-center">
            <span className="loading-msg">{upgradeMessage}</span>
          </div>
        </Modal>
      )}
      <section className="back">
        <div className="container">
          <a role="button">
            <img
              src={backImgBlack}
              alt="back-btn"
              onClick={() => {
                history.push(`/residentialfacility/edit/${info?.id}/1`);
                // dispatch(residentialAction.isChangeTrue(false));
              }}
            />
          </a>
        </div>
      </section>
      <section className="listing sober-living-listing-wrapper pt-4 mt-1">
        <div className="container">
          <div className="listing-header test mb-0">
            <h3 className="main-title mb-0">
              {info?.facilityType} Facility SUBSCRIPTION{" "}
              <span className="step-badge">STEP 2</span>
            </h3>
          </div>
          <div classNameName="subscription-wrp sober-living-subscription-wrp pt-4 pt-md-5 mt-md-4">
            <div className="subscription-title sobar-living-subscription-title">
              <h3 style={{ marginTop: "40px" }}>
                CHOOSE ANY SUBSCRIPTION TO GET LISTED
              </h3>
              <span className="addingform">
                Select a plan that best fits yours business needs
              </span>
            </div>
            <div className="plan-list sober-living-plan-list justify-content-center pt-4 mt-md-3">
              <div
                onClick={() => {
                  setShowSubscriptionBasic(true);
                  setShowSubscriptionPro(false);
                }}
                className="plan-card"
              >
                <h3>Basic</h3>
                <h4>
                  {state?.residentialPlan[0]?.isNormalFlow ? (
                    <b>${state?.residentialPlan[0]?.unit_amount}/Month</b>
                  ) : (
                    <b>${state?.residentialPlan[0]?.unit_amount}</b>
                  )}
                </h4>

                <button
                  type="submit"
                  className="btn btn-primary radius-20 mb-20"
                  onClick={() => handleNormalSubs("basic")}
                >
                  Buy Now
                </button>
                <div className="plan-info">
                  <ul>
                    <li>Standard visibility</li>
                    <li>Facility center details</li>
                    <li>Upload one photo to showcase your facility</li>
                    <li>List your phone number for direct contact</li>
                    <li>Showcase your website link</li>
                    <li>Connect with potential clients via in-platform chat</li>
                    <li>Access and view reviews</li>
                    <li>Recommend more facilities</li>
                  </ul>
                </div>
              </div>
              <div
                onClick={() => {
                  setShowSubscriptionPro(true);
                  setShowSubscriptionBasic(false);
                }}
                className="plan-card"
              >
                <h3>Pro</h3>
                <h4>
                  {state?.residentialPlan[0]?.isNormalFlow ? (
                    <b>${state?.residentialPlan[2]?.unit_amount}/Month</b>
                  ) : (
                    <b>${state?.residentialPlan[1]?.unit_amount}</b>
                  )}
                </h4>
                <button
                  type="submit"
                  className="btn btn-primary radius-20 mb-20"
                  onClick={() => handleNormalSubs("pro")}
                >
                  Buy Now
                </button>
                <div className="plan-info">
                  <ul>
                    <li className="highlight">
                      <b>Includes all basic options</b>
                    </li>
                    <li>Highest visibility</li>
                    <li>Upload up to 10 photos for a more detailed showcase</li>
                    <li>
                      Offer a virtual facility tour link to engage potential
                      clients
                    </li>
                    <li>
                      Predefined survey questionnaire in the chat to assist in
                      quicker responses and personalized care
                    </li>
                    <li>Respond to reviews for enhanced communication</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>

          {isOpen && (
            <div
              className="show modal pro-plan-modal upgrade-plan sobar-living-upgrade-plan fade"
              id="exampleModal"
              tabIndex="-1"
              role="dialog"
              aria-labelledby="exampleModalLabel"
              aria-hidden="true"
            >
              <div
                className="modal-dialog modal-dialog-centered"
                role="document"
              >
                <div className="modal-content">
                  <div className="pro-plan-content upgrade-plan-content">
                    <div className="modal-header flex-wrap">
                      <div className="modal-header-top d-flex w-100 align-items-center justify-content-between">
                        <a role="button" className="d-block d-md-none">
                          <img
                            src={backImgBlack}
                            alt="back-btn"
                            onClick={() => setIsOpen(false)}
                          />
                        </a>
                        <a
                          className="cancel-btn"
                          role="button"
                          onClick={() => {
                            setIsOpen(false);
                            setIsBasic(false);
                            setIsPro(false);
                            setCouponCode("");
                            dispatch(paymentActions.resetCouponData());
                          }}
                        >
                          <img
                            src={require("../../assets/images/cancle.svg")}
                            className="d-none d-md-block"
                            alt="cancel-btn"
                          />{" "}
                          <span className="d-block d-md-none">CANCEL</span>
                        </a>
                      </div>
                      {isPro && <h3 className="w-100 mt-0">Pro Plan</h3>}
                      {isBasic && <h3 className="w-100 mt-0">Basic Plan</h3>}
                    </div>
                    <div className="plan-content">
                      {isBasic && (
                        <div className="plan-info flex-column">
                          <p>
                            Our Basic Plan subscription includes standard
                            visibility, facility center details, usage of the
                            chat feature, one photo upload, past client reviews,
                            and website and phone number links listed.
                          </p>
                          <p className="mb-0">
                            Subscriptions are automatically renewed and billed
                            on a monthly or annual basis until you cancel.
                            Annual subscriptions are paid up front and are based
                            on a one-year commitment. You may change or cancel
                            your subscription at any time. All subscription
                            payments are non-refundable.
                            <br />
                            <p>
                              <label htmlFor="price">Note:</label> &nbsp; Change
                              or cancellation of plan would clear the applied
                              discount coupon for your existing plan.{" "}
                            </p>
                          </p>
                        </div>
                      )}
                      {isPro && (
                        <div className="plan-info flex-column">
                          <p>
                            Our Pro Plan subscription includes highest
                            visibility, facility center details, usage of the
                            chat feature with automated survey questions decided
                            on by you, unlimited photo uploads, past client
                            reviews with the ability to respond to reviews, link
                            a video or virtual tour, and website and phone
                            number links listed.
                          </p>
                          <p className="mb-0">
                            Subscriptions are automatically renewed and billed
                            on a monthly or annual basis until you cancel.
                            Annual subscriptions are paid up front and are based
                            on a one-year commitment. You may change or cancel
                            your subscription at any time. All subscription
                            payments are non-refundable.
                            <br />
                            <p>
                              <label htmlFor="price">Note:</label> &nbsp; Change
                              or cancellation of plan would clear the applied
                              discount coupon for your existing plan.{" "}
                            </p>
                          </p>
                        </div>
                      )}
                      <form action="#">
                        <div className="subscribePlan">
                          <input
                            type="radio"
                            id="monthlyPlan"
                            value="monthly"
                            checked={recurringPeriod == "monthly"}
                            onClick={() => {
                              dispatch(paymentActions.resetCouponData());
                              setCouponCode("");
                              setRecurringPeriod("monthly");
                              setPriceId(basicDetails[0]?.id);
                              setPriceAmount(basicDetails[0]?.unit_amount);
                            }}
                            name="subscription plan"
                          />
                          <label htmlFor="price">Monthly</label>
                        </div>
                        <div className="subscribePlan">
                          <input
                            type="radio"
                            id="yearlyPlan"
                            value="yearly"
                            onClick={() => {
                              dispatch(paymentActions.resetCouponData());
                              setCouponCode("");
                              setRecurringPeriod("yearly");
                              setPriceId(basicDetails[1]?.id);
                              setPriceAmount(basicDetails[1]?.unit_amount);
                            }}
                            checked={recurringPeriod == "yearly"}
                            name="subscription plan"
                          />
                          <label htmlFor="price">Yearly</label>
                        </div>
                      </form>
                      <div className="plan-price-content my-4">
                        <span>You will be charged</span>
                        <h3>
                          ${priceAmount}
                          <span>
                            {recurringPeriod === "monthly" ? "Month" : "Year"}
                          </span>
                        </h3>
                      </div>
                      <div className="plan-coupon-content py-1">
                        <form
                          className="flex-column align-items-start mt-0"
                          action="#"
                        >
                          <label className="mb-3">Apply Coupon</label>
                          <div className="form-group d-flex align-items-center justify-content-between">
                            <input
                              type="text"
                              name="applyCoupon"
                              placeholder="Enter coupon code"
                              value={couponCode}
                              disabled={couponData?.discount}
                              onChange={(e) => setCouponCode(e.target.value)}
                            />
                            {!couponData?.discount && (
                              <button
                                type="button"
                                className="apply-btn"
                                onClick={handleApplyCoupon}
                              >
                                Apply
                              </button>
                            )}
                            {couponData?.discount && (
                              <button
                                type="button"
                                className="apply-btn"
                                onClick={() => {
                                  setCouponCode("");
                                  dispatch(paymentActions.resetCouponData());
                                }}
                              >
                                Remove
                              </button>
                            )}
                          </div>
                        </form>
                      </div>

                      <div className="plan-price-details mt-3">
                        <div className="detail-text d-flex align-items-center justify-content-between">
                          <p>Total Amount</p>
                          <p>${priceAmount}</p>
                        </div>
                        <div className="detail-text d-flex align-items-center justify-content-between">
                          <p>Coupon Discount</p>
                          <p>
                            {couponData?.discount
                              ? "$" + couponData?.discount
                              : ""}
                          </p>
                        </div>
                        <div className="to-pay-content d-flex align-items-center justify-content-between">
                          <p>Pay</p>
                          <p>
                            ${" "}
                            {couponData?.discountedPrice
                              ? couponData?.discountedPrice
                              : priceAmount}
                            /{recurringPeriod === "monthly" ? "Month" : "Year"}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="modal-footer">
                    <button
                      type="button"
                      className="btn btn-outline radius-20"
                      data-dismiss="modal"
                      onClick={() => {
                        setIsOpen(false);
                        setIsBasic(false);
                        setIsPro(false);
                        setCouponCode("");
                        dispatch(paymentActions.resetCouponData());
                      }}
                    >
                      Cancel
                    </button>
                    <button
                      type="button"
                      className="btn btn-primary radius-20"
                      onClick={() =>
                        state?.residentialByID?.subscriptionDetail
                          ? getMeassageforUpgrade()
                          : paySubscription()
                      }
                    >
                      Pay Subscription
                    </button>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </section>
    </>
  );
};

export default ResidentialSubscription;
