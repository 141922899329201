import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import filterIconOrange from "../../assets/images/filter-new-orange.svg";
import searchIcon from "../../assets/images/search-24px-dark.svg";
import { useHistory } from "react-router-dom";
import noresultIcon from "../../assets/images/no-results.svg";
import { homeAction } from "../../actions";
import pinImg from "../../assets/images/ic_pin_small.svg";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import personalAmenities from "../../assets/images/Personal Amenities.svg";
import locationImage from "../../assets/images/location-new.svg";
import locationDisableImage from "../../assets/images/location-disable.svg";
import { checkHasNavigationPermission } from "../../utils";
import locationModalImage from "../../assets/images/with-location.png";
import locationDisableModalImage from "../../assets/images/without-location.png";
const FacilityHeader = ({
  setStateName,
  perPage,
  getsCords,
  setIsSearchModelOpen,
  getsModelStatus,
  activeFacility,
  setActiveFacility,
  setOpenFilterModal,
  setSearchInput,
  searchInput,
  searchInputtext,
  setSearchInputtext,
}) => {
  const dispatch = useDispatch();
  const facilityListFromState = useSelector((state) => state?.home?.facilityList);
  const filteredFacilityList = useSelector((state) => state?.home?.filterFacilityList);
  const location = useLocation();
  const history = useHistory();
  const [title, setTitle] = useState("Find Residential Treatment Center");

  const debounceRef = useRef(null);
  const modalRef = useRef(null);

  const facilityCenter = [
    { value: "detox", label: "Detox", link: "detox" },
    { value: "residential", label: "Residential", link: "" },
    { value: "php", label: "PHP", link: "php" },
    { value: "iop", label: "IOP", link: "iop" },
    { value: "sober", label: "Sober Living", link: "soberliving" },
  ];

  useEffect(() => {
    if (activeFacility === "residential") {
      setTitle("Discover a Residential Treatment Center that meets <em><span style='color: #ff5f5f;'>your</span></em> needs");
    } else if (activeFacility === "detox") {
      setTitle("Discover a Detox Center that meets <em><span style='color: #ff5f5f;'>your</span></em> needs");
    } else if (activeFacility === "iop") {
      setTitle("Discover an Intensive Outpatient Program that meets <em><span style='color: #ff5f5f;'>your</span></em> needs");
    } else if (activeFacility === "php") {
      setTitle("Discover a Partial Hospitalization Program that meets <em><span style='color: #ff5f5f;'>your</span></em> needs");
    }
  }, [activeFacility]);  

  const [searchModel, setSearchModel] = useState(false);
  const [activeLocIndex, setActiveLocIndex] = useState(null);
  const [activeIndex, setActiveIndex] = useState(null);
  const [autocompleteResults, setAutocompleteResults] = useState([]);
  const [filteredFacilities, setFilteredFacilities] = useState([]);
  const [grantedPermission, setGrantedPermission] = useState(false);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    document.body.style.overflow = open ? "hidden" : "unset";
  }, [open]);

  useEffect(() => {
    const getLocationStatus = async () => {
      const granted = await checkHasNavigationPermission();
      setGrantedPermission(granted);
    };
    getLocationStatus();
  }, []);

  const debounceFetchAutocompleteResults = (input) => {
    if (debounceRef.current) clearTimeout(debounceRef.current);
    debounceRef.current = setTimeout(() => {
      fetchAutocompleteResults(input);
    }, 300);
  };

  const handleSearch = (e) => {
    const val = e.target.value;
    setSearchInputtext(val);
    debounceFetchAutocompleteResults(val);
    if (val === "") {
      getsCords({});
      setSearchInput("");
    }
  };

  useEffect(() => {
    getsModelStatus(searchInputtext.length > 0);
  }, [searchInputtext, getsModelStatus]);

  const handleSearchOnKeyDown = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      dispatch(homeAction.setSearchInputField(searchInputtext));
      getsCords({});
      setSearchModel(false);
      getsModelStatus(false);
    }
  };

  useEffect(() => {
    const debounceDelay = 300;
    const handler = setTimeout(() => {
      if (searchInputtext.trim() !== "") {
        dispatch(
          homeAction.fetchFacilityListForFilter({
            perPage: 9,
            searchBar: searchInputtext,
            facilityType: location?.pathname === "/" ? "residential" : location?.pathname.replace("/", ""),
          })
        );
      }
    }, debounceDelay);
    return () => clearTimeout(handler);
  }, [location?.pathname, searchInputtext, dispatch, perPage]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        setSearchModel(false);
      }
    };

    if (searchModel) document.addEventListener("mousedown", handleClickOutside);
    else document.removeEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [searchModel]);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setSearchInput(searchInputtext);
    }, 1000);
    return () => clearTimeout(timeoutId);
  }, [searchInputtext, setSearchInput]);

  const fetchAutocompleteResults = (input) => {
    if (!window.google || !input) return;
    const service = new window.google.maps.places.AutocompleteService();
    service.getPlacePredictions({ input }, (predictions, status) => {
      if (status === window.google.maps.places.PlacesServiceStatus.OK) {
        const filteredData = predictions.filter((item) =>
          item.types.includes("administrative_area_level_1") ||
          item.types.includes("geocode") ||
          item.types.includes("locality")
        ).filter((result) =>
          result.description.toLowerCase().includes(input.toLowerCase()) // filter by search input
        );
        setAutocompleteResults(filteredData);
      } else {
        setAutocompleteResults([]);
      }
    });
  };

  const handlePlaceSelected = async (place) => {
    if (place.geometry) {
      const latitude = place.geometry.location.lat();
      const longitude = place.geometry.location.lng();
      const coordinates = { lat: Number(latitude), lng: longitude };
      getsCords(coordinates);
      const stateNameFound = place?.address_components?.find((item) =>
        item?.types?.includes("administrative_area_level_1")
      )?.long_name;
      setStateName(stateNameFound);
      await dispatch(homeAction.setSearchCoodinates(coordinates));
      await dispatch(homeAction.setSearchInputField(place.formatted_address));
    } else {
      dispatch(homeAction.setSearchCoodinates({}));
      console.error("No geometry available for the selected place.");
    }
    setIsSearchModelOpen(false);
  };

  const handleSuggestionClick = (description, placeId) => {
    const service = new window.google.maps.places.PlacesService(document.createElement("div"));
    setSearchModel(false);
    setSearchInputtext(description);
    service.getDetails({ placeId }, (place, status) => {
      if (status === window.google.maps.places.PlacesServiceStatus.OK) {
        handlePlaceSelected(place);
      }
    });
  };

  const handleMouseOverLocation = (index) => setActiveLocIndex(index);
  const handleMouseOverLocationFacility = (index) => setActiveIndex(index);

  useEffect(() => {
    if (filteredFacilityList?.facilities) {
      const filteredFacilities = filteredFacilityList.facilities.filter((facility) => {
        return (
          facility.name.toLowerCase().includes(searchInputtext.toLowerCase()) ||
          facility.city.toLowerCase().includes(searchInputtext.toLowerCase()) ||
          facility.state.toLowerCase().includes(searchInputtext.toLowerCase())
        );
      });
      setFilteredFacilities(filteredFacilities);
    } else {
      setFilteredFacilities([]);
    }
  }, [filteredFacilityList, searchInputtext]);


  return (
    <section className="facility-list-banner home-banner">
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <div className="banner-content">
              <div className="d-flex d-md-none align-items-center justify-content-between title">
                TASTE RECOVERY
                <div className="location-filter d-flex align-items-center">
                  <button
                    type="button"
                    onClick={() => setOpen(true)}
                    className="btn btn-location d-flex align-items-center justify-content-center"
                  >
                    <img
                      src={grantedPermission ? locationImage : locationDisableImage}
                      className="location-icon"
                      alt="Location"
                    />
                  </button>
                  <button
                    type="button"
                    className="btn btn-filter d-flex align-items-center justify-content-center"
                    onClick={() => setOpenFilterModal(true)}
                  >
                    <img src={filterIconOrange} className="img-fluid" alt="Filter" />
                    Filters
                  </button>
                </div>
              </div>
              <ul className="banner-menu d-flex align-items-center justify-content-md-center pl-0 pb-2 mt-5 pb-md-3 mb-4 mb-md-4">
                {facilityCenter.map((center) => (
                  <li
                    key={center.value}
                    className="banner-menu-item"
                    style={{ cursor: "pointer" }}
                  >
                    <a
                      onClick={() => {
                        setActiveFacility(center.value);
                        history.push({
                          pathname: `/${center.link}`,
                          state: { facilityType: center.value },
                        });
                      }}
                      className={
                        location?.pathname.replace("/", "") === center.value ||
                        (location?.pathname === "/" &&
                          center.value === "residential")
                          ? "banner-menu-link active"
                          : "banner-menu-link"
                      }
                    >
                      {center.label}
                    </a>
                  </li>
                ))}
              </ul>
              <h1
                className="title"
                dangerouslySetInnerHTML={{ __html: title }}
              />
              <form ref={modalRef} className="search-wrapper w-100">
                <div className="form-group position-relative">
                  <input
                    className="form-control mb-0"
                    placeholder="Search by City, State or Facility Name"
                    value={searchInputtext}
                    onClick={() => setSearchModel(true)}
                    onChange={handleSearch}
                    onKeyDown={handleSearchOnKeyDown}
                  />
                  <span className="search-icon">
                    <img src={searchIcon} className="img-fluid" alt="Search" />
                  </span>
                </div>
                {searchModel && searchInputtext?.length > 0 && (
                  <div className="search-modal">
                    <div className="search-result-wrapper">
                      {autocompleteResults.length > 0 && (
                        <div className="search-result pb-0">
                          <h3>Locations</h3>
                          <ul className="search-result-list d-flex flex-column">
                            {autocompleteResults.map((result, index) => (
                              <li
                                key={index}
                                className={
                                  activeLocIndex === index
                                    ? "active d-flex align-items-center"
                                    : "d-flex align-items-center"
                                }
                                onMouseOver={() =>
                                  handleMouseOverLocation(index)
                                }
                                onClick={() =>
                                  handleSuggestionClick(
                                    result.description,
                                    result.place_id
                                  )
                                }
                              >
                                <img
                                  src={pinImg}
                                  alt="Location"
                                  className="img-fluid location-img"
                                />
                                <p className="mb-0">{result.description}</p>
                              </li>
                            ))}
                          </ul>
                        </div>
                      )}
                      {filteredFacilities.length > 0 && (
                        <div className="search-result pb-0">
                          <h3>Facilities</h3>
                          <ul className="search-result-list d-flex flex-column">
                            {filteredFacilities
                              .slice(0, 5)
                              .map((facility, index) => (
                                <li
                                key={facility.id}
                                className={
                                  activeIndex === index
                                    ? "active d-flex align-items-center"
                                    : "d-flex align-items-center"
                                }
                                onMouseOver={() =>
                                  handleMouseOverLocationFacility(index)
                                }
                                onClick={() => {
                                  history.push(
                                    `/home/centers/view/${facility?.id}`
                                  );
                                }}
                              >

                                  <img
                                    src={personalAmenities}
                                    alt="Facility"
                                    className="img-fluid location-img"
                                  />
                                  <p className="mb-0">
                                    {facility.name
                                      .split(" ")
                                      .map(
                                        (word) =>
                                          word.charAt(0).toUpperCase() +
                                          word.slice(1)
                                      )
                                      .join(" ")}{" "}
                                    - {facility.geocodingResult?.cityName},{" "}
                                    {facility.geocodingResult?.stateShort}
                                  </p>
                                </li>
                              ))}
                          </ul>
                        </div>
                      )}
                      {filteredFacilities.length === 0 &&
                        autocompleteResults.length === 0 &&
                        searchInputtext?.length > 0 && (
                          <div className="search-result">
                            <ul className="search-result-list d-flex flex-column">
                              <div className="no-result-found">
                                <img
                                  src={noresultIcon}
                                  className="img-fluid"
                                  alt="No results"
                                />
                                <h4>No results found</h4>
                                <p>
                                  Enter search term for searching locations or
                                  facilities
                                </p>
                              </div>
                            </ul>
                          </div>
                        )}
                    </div>
                  </div>
                )}
              </form>
            </div>
          </div>
        </div>
      </div>

      {open && (
        <div
          className="modal upgrade-plan cancel-subscription-modal fade show"
          id="exampleModal"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content">
              <div className="upgrade-plan-content">
                <h3>
                  Location Access {grantedPermission ? "Enabled" : "Disabled"}
                </h3>
                <span className="loading-msg">
                  Location services are currently{" "}
                  {grantedPermission ? "enabled" : "disabled"}. You can manage
                  location settings in the site settings.
                </span>
                <img
                  src={
                    grantedPermission
                      ? locationModalImage
                      : locationDisableModalImage
                  }
                  className="modal-image"
                  alt="Location"
                />
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="modal-btn"
                  onClick={() => setOpen(false)}
                >
                  Cancel
                </button>
                <button
                  type="button"
                  className="modal-btn confirm-btn"
                  onClick={() => setOpen(false)}
                >
                  Ok
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </section>
  );
};

export default FacilityHeader;