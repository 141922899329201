import React from "react";

import ReactHelmet from "../../components/react-helmet/react-helmet";
const Floridaheader = () => {
  return (
    <>
      <ReactHelmet
        title={"Facilities For Sober Living in Florida | Taste Recovery"}
        description={
          "Browse available facilities for sober living in Florida offering safe, peer-reviewed & supportive environments to help individuals maintain lasting recovery."
        }
      />
    </>
  );
};

export default Floridaheader;
