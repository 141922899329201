import React, { useState, useContext, useEffect, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { SpinnerContext } from "../../components/spinner/spinner.component";
import { homeAction } from "../../actions";
import { detoxFilter } from "./facilityCenters/filterOptions";
import FacilityHeader from "../../components/home/Header.component";
import FilterSection from "../../components/home/FilterSection.component";
import { FilteredOptions } from "./facilityCenters/filters";
import FilterModel from "../../components/home/filter.component";
import FacilityList from "../../components/home/facilityList.component";
import Logoutsessionmodal from "../../components/modal/Logoutsessionmodal";
import ReactHelmet from "../../components/react-helmet/react-helmet";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import Detoxheader from "../../components/content/Detoxheader";
import Detoxcontent from "../../components/content/Detoxcontent";
import {
  checkHasNavigationPermission,
  getGeoLocation,
  getCoordinates,
} from "../../utils";

const DetoxFacility = (props) => {
  const { getActiveFacility } = props;
  const param = useParams();
  const dispatch = useDispatch();
  const { showLoader, hideLoader } = useContext(SpinnerContext);

  const [activeFacility, setActiveFacility] = useState("detox");
  const [searchInput, setSearchInput] = useState("");
  const [searchInputtext, setSearchInputtext] = useState("");
  const [selectSearchOption, setSelectSearchOption] = useState({
    label: "Search by facility location",
    value: "location",
  });
  const [openFilterModal, setOpenFilterModal] = useState(false);
  const [recordsPerPage, setRecordsperPage] = useState(9);
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedItems, setSelectedItems] = useState([]);
  const [searchCords, setSearchCords] = useState({});
  const [isSearchModelOpen, setIsSearchModelOpen] = useState(true);

  const [specialized, setSpecialized] = useState([]);
  const [conditionTreated, setConditionTreated] = useState([]);
  const [medicationTherapy, setMedicationTherapy] = useState([]);
  const [treatmentApproaches, setTreatmentApproaches] = useState([]);
  const [preTreatment, setPreTreatment] = useState([]);
  const [testing, setTesting] = useState([]);
  const [smokingPolicy, setSmokingPolicy] = useState([]);
  const [isGenderSpecific, setIsGenderSpecific] = useState(false);
  const [insurance, setInsurance] = useState("");
  const [gender, setGender] = useState("");
  const [verifiedBadge, setIsVerifiedBadge] = useState(false);
  const [isFeatured, setIsFeatured] = useState(false);
  const [finalFilter, setFinalFilter] = useState(detoxFilter);
  const [isScholarshipOffer, setIsScholarshipOffer] = useState(false);
  const [facilityCount, setFacilityCount] = useState("");
  const [allowableStay, setAllowableStay] = useState({ from: "", to: "" });
  const [stateName, setStateName] = useState("");

  const [getData, setGetData] = useState(null);

  const stateValues = {
    specialized,
    conditionTreated,
    medicationTherapy,
    treatmentApproaches,
    preTreatment,
    testing,
    smokingPolicy,
    isGenderSpecific,
    gender,
    verifiedBadge,
    isFeatured,
    finalFilter,
    facilityCount,
    allowableStay,
    isScholarshipOffer,
  };

  const setValues = {
    setSpecialized,
    setConditionTreated,
    setMedicationTherapy,
    setPreTreatment,
    setTreatmentApproaches,
    setTesting,
    setIsGenderSpecific,
    setGender,
    setSmokingPolicy,
    setIsFeatured,
    setIsVerifiedBadge,
    setAllowableStay,
    setIsScholarshipOffer,
    setSelectedItems,
    setInsurance,
  };

  const isMobile = () => window.innerWidth <= 575;

  useEffect(() => {
    dispatch(homeAction.setParam(param));
  }, [param, dispatch]);

  useEffect(() => {
    const initCoords = async () => {
      try {
        const granted = await checkHasNavigationPermission();
        if (granted) {
          const position = await getGeoLocation();
          const { latitude, longitude } = position.coords;
          const coords = { lat: Number(latitude), lng: Number(longitude) };
          setSearchCords(coords);
          localStorage.setItem("coordinates", JSON.stringify({ coordinates: coords }));
        }
      } catch (error) {
        console.error("Error obtaining geolocation:", error);
      }
    };
    initCoords();
  }, []);

  useEffect(() => {
    setCurrentPage(1);
  }, [
    activeFacility,
    specialized,
    conditionTreated,
    medicationTherapy,
    treatmentApproaches,
    preTreatment,
    testing,
    smokingPolicy,
    isGenderSpecific,
    gender,
    isFeatured,
    verifiedBadge,
    insurance,
    isScholarshipOffer,
    searchInput,
    stateName,
  ]);

  const buildData = useCallback(() => {
    const payload = {
      facilityType: activeFacility,
      perPage: recordsPerPage,
      page: currentPage,
    };
    const searchKey = Object.keys(searchCords).length > 0 ? stateName : searchInput;
    // if (searchKey && searchKey.trim() !== "") {
    //   payload.searchBar = searchKey;
    // }
    if (conditionTreated.length) payload.conditionTreated = conditionTreated;
    if (specialized.length) payload.specialized = specialized;
    if (medicationTherapy.length) payload.medicationTherapy = medicationTherapy;
    if (isGenderSpecific) payload.genderSpecific = isGenderSpecific;
    if (gender) payload.gender = gender;
    if (treatmentApproaches.length) payload.treatmentApproaches = treatmentApproaches;
    if (preTreatment.length) payload.preTreatment = preTreatment;
    if (testing.length) payload.testing = testing;
    if (smokingPolicy.length) payload.smokingPolicy = smokingPolicy;
    if (verifiedBadge) payload.verifiedBadge = verifiedBadge;
    if (isFeatured) payload.featured = isFeatured;
    if (allowableStay.from || allowableStay.to) payload.allowableStay = allowableStay;
    if (insurance) payload.insurance = insurance;
    if (isScholarshipOffer) payload.offersScholarships = isScholarshipOffer;
    if (Object.keys(searchCords).length > 0) {
      payload.coordinates = searchCords;
    } else {
      const coords = getCoordinates()?.coordinates;
      if (coords) payload.coordinates = coords;
    }
    return payload;
  }, [
    activeFacility,
    recordsPerPage,
    currentPage,
    searchCords,
    stateName,
    searchInput,
    conditionTreated,
    specialized,
    medicationTherapy,
    isGenderSpecific,
    gender,
    treatmentApproaches,
    preTreatment,
    testing,
    smokingPolicy,
    verifiedBadge,
    isFeatured,
    allowableStay,
    insurance,
    isScholarshipOffer,
  ]);

  const fetchData = useCallback(async () => {
    const payload = buildData();
    try {
      if (!openFilterModal && !isSearchModelOpen) {
        showLoader(`Finding the best detox centers near you`);
      }
      await dispatch(homeAction.fetchFacilityList(payload));
    } catch (error) {
      console.error("Error fetching facility list:", error);
    } finally {
      hideLoader();
    }
  }, [buildData, dispatch, openFilterModal, isSearchModelOpen, showLoader, hideLoader, searchCords]);

  useEffect(() => {
    if (!isSearchModelOpen) {
      fetchData();
    }
  }, [
    isSearchModelOpen,
    searchCords,
    activeFacility,
    conditionTreated,
    specialized,
    medicationTherapy,
    treatmentApproaches,
    preTreatment,
    testing,
    smokingPolicy,
    isGenderSpecific,
    gender,
    isFeatured,
    verifiedBadge,
    recordsPerPage,
    currentPage,
    insurance,
    isScholarshipOffer,
    searchInput,
    stateName,
    fetchData,
  ]);

  const facilityListFromStore = useSelector((state) => state?.home?.facilityList);
  const [aggregatedFacilities, setAggregatedFacilities] = useState([]);
  useEffect(() => {
    if (currentPage === 1) {
      setAggregatedFacilities(facilityListFromStore?.facilities || []);
    } else {
      setAggregatedFacilities((prev) => {
        const newData = facilityListFromStore?.facilities || [];
        const filteredNewData = newData.filter(
          (item) => !prev.some((prevItem) => prevItem.id === item.id)
        );
        return [...prev, ...filteredNewData];
      });
    }
  }, [facilityListFromStore, currentPage]);

  const handleCheckboxChange = (option, checkbox) => {
    if (checkbox === "specialized") {
      if (specialized.includes(option)) {
        setSpecialized(specialized.filter((value) => value !== option));
        setSelectedItems(selectedItems.filter((value) => value !== option));
      } else {
        setSpecialized([...specialized, option]);
        setSelectedItems([...selectedItems, option]);
      }
    } else if (checkbox === "conditionTreated") {
      if (conditionTreated.includes(option)) {
        setConditionTreated(conditionTreated.filter((value) => value !== option));
        setSelectedItems(selectedItems.filter((value) => value !== option));
      } else {
        setConditionTreated([...conditionTreated, option]);
        setSelectedItems([...selectedItems, option]);
      }
    } else if (checkbox === "medicationTherapy") {
      if (medicationTherapy.includes(option)) {
        setMedicationTherapy(medicationTherapy.filter((value) => value !== option));
        setSelectedItems(selectedItems.filter((value) => value !== option));
      } else {
        setMedicationTherapy([...medicationTherapy, option]);
        setSelectedItems([...selectedItems, option]);
      }
    } else if (checkbox === "treatmentApproaches") {
      if (treatmentApproaches.includes(option)) {
        setTreatmentApproaches(treatmentApproaches.filter((value) => value !== option));
        setSelectedItems(selectedItems.filter((value) => value !== option));
      } else {
        setTreatmentApproaches([...treatmentApproaches, option]);
        setSelectedItems([...selectedItems, option]);
      }
    } else if (checkbox === "preTreatment") {
      if (preTreatment.includes(option)) {
        setPreTreatment(preTreatment.filter((value) => value !== option));
        setSelectedItems(selectedItems.filter((value) => value !== option));
      } else {
        setPreTreatment([...preTreatment, option]);
        setSelectedItems([...selectedItems, option]);
      }
    } else if (checkbox === "testing") {
      if (testing.includes(option)) {
        setTesting(testing.filter((value) => value !== option));
        setSelectedItems(selectedItems.filter((value) => value !== option));
      } else {
        setTesting([...testing, option]);
        setSelectedItems([...selectedItems, option]);
      }
    } else if (checkbox === "smokingPolicy") {
      if (smokingPolicy.includes(option)) {
        setSmokingPolicy(smokingPolicy.filter((value) => value !== option));
        setSelectedItems(selectedItems.filter((value) => value !== option));
      } else {
        setSmokingPolicy([...smokingPolicy, option]);
        setSelectedItems([...selectedItems, option]);
      }
    }
  };

  const handleOnChange = (key, value) => {
    switch (key) {
      case "isGenderSpecific":
        setIsGenderSpecific(value);
        if (value && !selectedItems.includes("genderSpecific")) {
          setSelectedItems([...selectedItems, "genderSpecific"]);
        } else if (!value) {
          setSelectedItems(selectedItems.filter((item) => item !== "genderSpecific"));
        }
        break;
      case "gender":
        setGender(value);
        break;
      case "from":
        setAllowableStay({ ...allowableStay, from: value });
        break;
      case "to":
        setAllowableStay({ ...allowableStay, to: value });
        break;
      case "verifiedBadge":
        setIsVerifiedBadge(value);
        break;
      case "isFeatured":
        setIsFeatured(value);
        break;
      case "isScholarshipOffer":
        setIsScholarshipOffer(value);
        break;
      default:
        break;
    }
  };

  const handleConditionTreated = (key, value) => {
    const index = selectedItems.indexOf(value);
    if (index === -1) {
      setSelectedItems([...selectedItems, value]);
      switch (key) {
        case "specialized":
          setSpecialized([...specialized, value]);
          break;
        case "conditionTreated":
          setConditionTreated([...conditionTreated, value]);
          break;
        case "isGenderSpecific":
          setIsGenderSpecific(!isGenderSpecific);
          break;
        case "treatmentApproaches":
          setTreatmentApproaches([...treatmentApproaches, value]);
          break;
        case "insurance":
          setInsurance((prev) => (Array.isArray(prev) ? [...prev, value] : value));
          break;
        default:
          break;
      }
    } else {
      setSelectedItems(selectedItems.filter((item) => item !== value));
      switch (key) {
        case "specialized":
          setSpecialized(specialized.filter((item) => item !== value));
          break;
        case "conditionTreated":
          setConditionTreated(conditionTreated.filter((item) => item !== value));
          break;
        case "isGenderSpecific":
          setIsGenderSpecific(!isGenderSpecific);
          break;
        case "treatmentApproaches":
          setTreatmentApproaches(treatmentApproaches.filter((item) => item !== value));
          break;
        case "insurance":
          setInsurance((prev) => (Array.isArray(prev) ? prev.filter((item) => item !== value) : ""));
          break;
        default:
          break;
      }
    }
  };

  const handleSearch = (e) => {
    const val = e.target.value;
    setSearchInputtext(val);
  };

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setSearchInput(searchInputtext);
    }, 1000);
    return () => clearTimeout(timeoutId);
  }, [searchInputtext]);

  const getRecordsPerPage = (data) => {
    setRecordsperPage(data);
  };

  const getsCords = (data) => {
    setSearchCords(data);
    const payload = {
      facilityType: activeFacility,
      perPage: recordsPerPage,
      page: 1, 
      coordinates: data,
    };

    showLoader(`Finding the best detox centers near you`);
    dispatch(homeAction.fetchFacilityList(payload))
      .then(() => {
        hideLoader();
      })
      .catch((error) => {
        console.error("Error fetching facility list:", error);
        hideLoader();
      });
  };

  const getsModelStatus = (data) => {
    setIsSearchModelOpen(data);
  };

  useEffect(() => {
    if (activeFacility === "sober") {
      getActiveFacility(true);
    }
  }, [activeFacility, getActiveFacility]);

  return (
    <>
      <ReactHelmet
        title="Drug and Addiction Detox Programs | Taste Recovery"
        description="Explore Taste Recovery's drug & addiction detox programs designed to provide flexible & effective treatment for those seeking recovery and support."
      />
      <FacilityHeader
        activeFacility={activeFacility}
        setActiveFacility={setActiveFacility}
        setSearchInput={setSearchInput}
        setStateName={setStateName}
        searchInputtext={searchInputtext}
        setSearchInputtext={setSearchInputtext}
        selectSearchOption={selectSearchOption}
        setSelectSearchOption={setSelectSearchOption}
        setOpenFilterModal={setOpenFilterModal}
        getsCords={getsCords}
        getsModelStatus={getsModelStatus}
        perPage={facilityListFromStore?.total}
        setIsSearchModelOpen={setIsSearchModelOpen}
      />
      <section className="facility-list-wrapper">
        <FilterSection
          FilteredOptions={FilteredOptions}
          selectedItems={selectedItems}
          handleConditionTreated={handleConditionTreated}
          isMobile={isMobile}
          setOpenFilterModal={setOpenFilterModal}
        />
        <FacilityList
          facilities={aggregatedFacilities}
          total={facilityListFromStore?.total}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          recordsPerPage={recordsPerPage}
        />
        <Detoxheader />
        <Detoxcontent />
      </section>
      {openFilterModal && (
        <FilterModel
          openFilterModal={openFilterModal}
          setOpenFilterModal={setOpenFilterModal}
          selectedItems={selectedItems}
          handleConditionTreated={handleConditionTreated}
          stateValues={stateValues}
          facilityType={activeFacility}
          searchBar={searchInput}
          getPostData={setGetData}
          conditionTreated={conditionTreated}
          handleCheckboxChange={handleCheckboxChange}
          handleOnChange={handleOnChange}
          setValues={setValues}
          total={facilityListFromStore?.total}
          isScholarshipOffer={isScholarshipOffer}
          open={openFilterModal}
          onCancel={() => setOpenFilterModal(false)}
        />
      )}
      <Logoutsessionmodal />
    </>
  );
};

export default DetoxFacility;
