import React, { Component } from "react";

class LivingDetailSliderCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      objFit: "cover",
    };
    this.onImgLoad = this.onImgLoad.bind(this);
  }
  render() {
    return (
      <div className="property-img">
        <img
          alt={this?.props?.title}
          src={this.props.item}
          style={{ objectFit: this.state.objFit }}
          onLoad={this.onImgLoad}
        />
      </div>
    );
  }
  onImgLoad({ target: img }) {
    try {
      if (img.naturalWidth < img.offsetWidth) {
        // this.setState({ objFit: "fill" })
        this.setState({ objFit: "cover" });
      } else this.setState({ objFit: "cover" });
    } catch (e) {}
  }
}

export default LivingDetailSliderCard;
