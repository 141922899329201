import React, { useState } from "react";
import { formatCamelCaseToReadableadditionaservicesforesidentailResidential } from "../../constants/utils";
const itemsPerPage = 15;
const ViewField = (props) => {
  const { title, img, data, format, verifyInsurance } = props;
  const [currentPage, setCurrentPage] = useState(1);
  const [showAll, setShowAll] = useState(false);
  const totalItems = Object.values(data).filter((value) => value).length;
  const currentItems = Object.entries(data)
    .filter(([_, value]) => value)
    .slice(0, showAll ? totalItems : currentPage * itemsPerPage);
  const toggleShowAll = () => {
    setShowAll(!showAll);
    setCurrentPage(1);
  };

  return (
    <div className="facility-content">
      <h3 className="d-flex align-items-center">
        <img src={img} className="img-fluid" alt={title} />
        {title}
      </h3>
      <ul className="d-flex align-items-start flex-wrap">
        {currentItems.map(([key, _]) => {
          const formattedKey = format(key);
          return (
            <li key={key}>
             {formattedKey}
            </li>
          );
        })}
        {totalItems > itemsPerPage && (
          <li className="more-button-list">
            <button className="more-button" onClick={toggleShowAll}>
              {showAll
                ? "Show Less"
                : totalItems - currentPage * itemsPerPage > 0
                ? `+ ${totalItems - currentPage * itemsPerPage} More`
                : "Show Less"}
            </button>
          </li>
        )}
      </ul>

      {props?.title === "Insurance Accepted" &&
        verifyInsurance?.trim() !== "" &&
        verifyInsurance !== "https://www." &&
        verifyInsurance !== "http://www." && (
          <button
            onClick={(e) => {
              e.preventDefault();
              window.open(`${verifyInsurance}`, "_blank");
            }}
            type="button"
            className="btn btn-primary radius-20 m-0 px-4 mt-4"
          >
            Verify Insurance
          </button>
        )}
    </div>
  );
};

export default ViewField;
