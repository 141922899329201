import React, { Component } from 'react';
import { connect } from 'react-redux';
import { homeAction } from '../../actions';


class PlansPricing extends Component {
    constructor(props) {
        super(props);
        this.state = {
            planPrice: '',
            amount: '',
            interval: 'monthly',
        }
    }

    componentDidMount() {
        this.props.fetchPlan()
    }




    render() {

        return (
            <div>
                <section className="pt-0 pt-md-5 mt-0 mt-md-5 plans-pricing listing">
                    <div className="container">
                        <div className="row">
                            <div className="col-12">
                                <div className="subscription-wrp pt-5">
                                    <div className="subscription-title">
                                        <h3>PLANS & PRICING</h3>
                                    </div>

                                    <div className="togglediv d-flex flex-wrap align-items-center justify-content-center">
                                        <div className="pricing-toggle">

                                            {this.props.subscribePlans.map((data, index) => {
                                                return (
                                                    <div className='toggle-button-container d-flex align-items-center'>
                                                        <input type="radio" id={data?.id} name="pricing"
                                                            checked={this.state.interval === data?.recurring.interval}
                                                        />
                                                        <label className="radio-button" htmlFor={this.state.interval === 'monthly' ? "pricing-toggle-monthly" : "pricing-toggle-annually"}
                                                            onClick={() => {
                                                                this.setState({
                                                                    planPrice: data?.id,
                                                                    interval: data?.recurring.interval,
                                                                    amount: data?.unit_amount,
                                                                })


                                                            }}
                                                        >{data?.recurring.interval.charAt(0).toUpperCase() + data?.recurring.interval.slice(1)} </label></div>
                                                )
                                            })}
                                        </div>
                                        <div className="offer">Save up to 15%</div>
                                    </div>

                                    <div className="plan-list justify-content-center">
                                        <div className="plan-card activecard"
                                        >
                                            <span className="save-para"></span>
                                            <h3>Basic</h3>
                                            <h4><b>Free</b></h4>
                                            <div className="plan-info">
                                                <ul>
                                                    <li>House Rules</li>
                                                    <li>Curfew</li>
                                                    <li>Amenities</li>
                                                    <li>Requirements</li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div className="plan-card">
                                            <span className="save-para">{this.state.interval != 'monthly' ? '$23.50 savings/year' : ''}</span>
                                            <h3>Pro</h3>
                                            <h4><b>${this.state.amount ? this.state.amount : this.props.subscribePlans?.[0]?.unit_amount}/home/{this.state.interval}</b></h4>
                                            <div className="plan-info">
                                                <ul>
                                                    <li>House Rules</li>
                                                    <li>Curfew</li>
                                                    <li>Amenities</li>
                                                    <li>Requirements</li>
                                                    <li>Customer Reviews</li>
                                                    <li>Website Listed</li>
                                                    <li>Phone Number Listed</li>
                                                    <li>Chat with potential clients through the <br />platform</li>
                                                    <li>Scheduling House Tours</li>
                                                    <li>Recommend more facilities</li>

                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>

        )
    }
}

export default connect(
    ({ home }) => ({
        subscribePlans: home.subscribePlan,
    }), homeAction
)(PlansPricing);