import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import { seoUrlText } from "../../constants";

class StateCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      objFit: 'cover'
    };
    this.onCardClick = this.onCardClick.bind(this);
  }

  render() {
    return (
      <Link
        className="card text-decoration-none"
        to={{
          pathname: `../living/search/${seoUrlText}-${this.props.item.stateLong.toLowerCase()}`,
           state: { lat: this.props.item.coordinates?.lat, lng: this.props.item.coordinates?.lng},
         
          // state: { stateLong: this.props.item.stateLong },
        }}
      >
        <div className="card-img">
          <img src={this.props.item.url} style={{ objectFit: this.state.objFit }} onLoad={this.onImgLoad} alt={`Sober Living Options in ${this.props.item.stateLong.toLowerCase()}`} />
        </div>
        <div className="card-body">
          <div className="card-heading">{this.props.item.stateLong}</div>
        </div>
      </Link>
    );
  }

  onImgLoad({ target: img }) {
    try {
      if (img.naturalWidth < img.offsetWidth)
        this.setState({ objFit: "fill" })
      else
        this.setState({ objFit: "cover" })
    } catch (e) {

    }
  }

  onCardClick() {
    this.props.history.push(
      `living/search/?stateLong=${this.props.item.stateLong}`
    );
  }
}

export default withRouter(StateCard);
