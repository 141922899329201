import React, { useContext, useEffect } from "react";
import cancleImg from "../../assets/images/cancle.svg";
import { useHistory } from "react-router-dom";
import { useState } from "react";
import { SpinnerContext } from "../../components/spinner/spinner.component";
import { useDispatch } from "react-redux";
import { livingAction, paymentActions } from "../../actions";
import checkMarkIcon from "../../assets/images/checkmark_check_tick-green_bg.svg";
import { addCommasToPrice } from "../../constants/utils";

const PayPerClick = (props) => {
  const { open, onClosePPC, id, isPPCSubscribed, houseType, ppcPerClickPrice } =
    props;
  const dispatch = useDispatch();
  const { setsubscribePlanId, setsubscribePlanType } =
    useContext(SpinnerContext);
  const history = useHistory();
  const priceOptions = [
    { id: 1, value: 10 },
    { id: 2, value: 20 },
    { id: 3, value: 30 },
  ];
  const [selectedOption, setSelectedOption] = useState(5);
  const [limitBudget, setLimitBudget] = useState(false);
  const [isSubmit, setIsSubmit] = useState(false);
  const [cancelModel, setCancelModel] = useState(false);
  const [maxBudget, setMaxBudget] = useState(false);
  const [lowBudget, setLowBudget] = useState(false);
  const max_Budget = 3000;
  const cancelSubscription = () => {
    setCancelModel(true);
    dispatch(paymentActions.cancelPPC({ facilityOrSoberLivingId: id }));
    dispatch(livingAction.fetchhostLivings());
  };

  useEffect(() => {
    if (open) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "unset";
    }
  }, [open]);

  return (
    <>
      {open && (
        <div
          className="modal upgrade-plan cancel-subscription-modal choose-facility-modal promote-ad-modal fade show"
          id="exampleModal"
          tabIndex={-1}
          role="dialog"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content">
              <div className="upgrade-plan-content text-left">
                <h3 className="text-left pb-2">Add Budget Allocation</h3>
                <a
                  onClick={() => {
                    onClosePPC(false);
                    setSelectedOption(5);
                  }}
                  className="cancel-btn"
                  role="button"
                >
                  <img src={cancleImg} className="img-fluid" alt="cancleImg" />
                </a>
                <div className="facility-list promote-ad-content">
                  <form action="#">
                    <div className="form-group">
                      <div className="dflex-form justify-content-start border-0 pb-1">
                        {priceOptions.map((option) => (
                          <div
                            className="custom-control custom-radio"
                            key={option.id}
                          >
                            <input
                              type="radio"
                              id={option.id}
                              value={option.value}
                              name="cause"
                              className="custom-control-input"
                              checked={selectedOption === option.value}
                              onChange={() => {
                                setSelectedOption(option.value);
                                if (option.value * 30 >= max_Budget) {
                                  setLimitBudget(true);
                                } else {
                                  setLimitBudget(false);
                                }
                              }}
                            />
                            <label
                              className="custom-control-label"
                              htmlFor={option.id}
                            >
                              ${option.value} <span>/day</span>
                            </label>
                          </div>
                        ))}
                      </div>
                    </div>
                    <div className="form-group mb-1 text-center">
                      <p className="mb-0">OR</p>
                    </div>

                    <div className="form-group mt-3 mb-4 pb-3">
                      <div className="slidecontainer d-flex align-items-center">
                        <h5 className="my-0">Customize maximum budget</h5>
                        <span className="pl-2">$</span>
                        <input
                          type="number"
                          id="ppc-custom"
                          inputmode="numeric"
                          pattern="[0-9]*"
                          className="form-control"
                          value={selectedOption}
                          min={1}
                          onChange={(e) => {
                            setSelectedOption(e.target.value);

                            if (e.target.value * 30 >= max_Budget) {
                              setLimitBudget(true);
                            } else {
                              setLimitBudget(false);
                            }
                          }}
                          onMouseLeave={(e) => {
                            if (selectedOption < 5) {
                              setLowBudget(true);
                              setSelectedOption(5);
                            } else if (selectedOption > 5000) {
                              setMaxBudget(true);
                              setSelectedOption(5);
                            }
                          }}
                          onFocus={(e) => {
                            setLowBudget(false);
                            setMaxBudget(false);
                          }}
                        />
                        <p className="my-0">
                          <span>/ day</span>
                        </p>
                      </div>
                    </div>
                  </form>
                  <h4 className="mb-4 pb-2">
                    Budget allocates ${addCommasToPrice(selectedOption * 30)}
                    /month maximum
                  </h4>
                  <div className="plan-info w-100">
                    <ul>
                      <li>
                        This will equate{" "}
                        {addCommasToPrice(
                          Math.round((selectedOption * 30) / ppcPerClickPrice)
                        )}{" "}
                        clicks / month @ ${ppcPerClickPrice} per click for your{" "}
                        {houseType} facility
                      </li>
                      <li>
                        You will be initially charged for the full amount of
                        your ad budget. At the end of the month, you will be
                        refunded for any unused portion of your budget within 7
                        days.
                      </li>
                      <li>
                        Ads are on a monthly subscription and will continue
                        until cancelled
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="submit"
                  className="modal-btn"
                  onClick={cancelSubscription}
                  disabled={!isPPCSubscribed}
                >
                  CANCEL SUBSCRIPTION
                </button>
                <button
                  type="submit"
                  className="modal-btn confirm-btn"
                  disabled={isPPCSubscribed}
                  onClick={() => {
                    setsubscribePlanId(selectedOption);
                    setsubscribePlanType("ppc");
                    setIsSubmit(true);
                    if (!limitBudget) {
                      history.push("/payment-options", {
                        data: {
                          id: id,
                          budget: selectedOption * 30,
                          houseType: houseType,
                        },
                      });
                    }
                  }}
                >
                  SUBMIT
                </button>
              </div>
            </div>
          </div>
        </div>
      )}

      {limitBudget && isSubmit && (
        <div
          className="modal upgrade-plan cancel-subscription-modal budget-modal show fade"
          id="exampleModal"
          tabIndex={-1}
          role="dialog"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
          style={{ zIndex: "9999" }}
        >
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content">
              <div className="upgrade-plan-content">
                <span className="loading-msg">
                  You are committing to a monthly budget of $
                  {addCommasToPrice(selectedOption * 30)}. Monthly costs are
                  based on cost per profile view. The cost per view rate will be
                  set at ${ppcPerClickPrice}/view. This agreement is a monthly
                  reoccurring subscription that can be canceled at any time.
                  Upon cancellation, the ad will end and you will no longer be
                  charged. Please agree to these terms.
                </span>
              </div>
              <div className="modal-footer">
                <button
                  type="submit"
                  className="modal-btn"
                  onClick={() => {
                    setIsSubmit(false);
                  }}
                >
                  No
                </button>
                <button
                  type="submit"
                  className="modal-btn confirm-btn"
                  onClick={() => {
                    setLimitBudget(false);
                    history.push("/payment-options", {
                      data: { id: id, budget: selectedOption * 30 },
                    });
                  }}
                >
                  Yes
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
      {cancelModel && (
        <div
          className="modal upgrade-plan cancel-subscription-modal updated-successfully-modal canceled-successfully show fade"
          id="exampleModal"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content">
              <div className="upgrade-plan-content">
                <img src={checkMarkIcon} className="img-fluid" alt="checkMarkIcon" />
                <span className="loading-msg d-block">
                  Your monthly ad promotion has been canceled successfully
                </span>
              </div>
              <div className="modal-footer">
                <button
                  type="submit"
                  className="modal-btn confirm-btn"
                  onClick={() => {
                    setCancelModel(false);
                    onClosePPC(false);
                  }}
                >
                  OKAY
                </button>
              </div>
            </div>
          </div>
        </div>
      )}

      {(lowBudget || maxBudget) && (
        <div
          className="modal upgrade-plan cancel-subscription-modal budget-modal show fade"
          id="exampleModal"
          tabIndex={-1}
          role="dialog"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
          style={{ zIndex: "9999" }}
        >
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content">
              <div className="upgrade-plan-content">
                {lowBudget && (
                  <span className="loading-msg">
                    Minimum budget $5 per day is mandatory{" "}
                  </span>
                )}
                {maxBudget && (
                  <span className="loading-msg">
                    Maximum budget should be $5000 per day{" "}
                  </span>
                )}
              </div>
              <div className="modal-footer">
                <button
                  type="submit"
                  className="modal-btn"
                  onClick={() => {
                    setMaxBudget(false);
                    setLowBudget(false);
                  }}
                >
                  okay
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default PayPerClick;
