import React, { useState } from "react";
const TexasFaq = () => {
  const [expandedIndex, setExpandedIndex] = useState(null);

  const toggleAccordion = (index) => {
    setExpandedIndex(expandedIndex === index ? null : index); // Close if it's already expanded, else open
  };

  const faqData = [
    {
      question: "What is a sober living home?",
      answer: (
        <>
          <p>
          Sober living homes provide drug-free spaces for people in recovery. They offer structure and support to help residents stay sober while working toward independent living.
          </p>
        </>
      ),
    },
    {
      question: "How long can I stay in a sober living home in Texas?",
      answer: (
        <>
          <p>
          Most homes let you stay as long as you need, as long as you follow the rules. The National Institute on Drug Abuse recommends at least 90 days for the best recovery results.
          </p>
        </>
      ),
    },
    {
      question: "Are sober living homes and halfway houses the same in Texas?",
      answer: (
        <>
          <p>
           No, they’re different. Sober living homes focus on recovery and independence. Halfway houses are usually an alternative to jail.
          </p>
        </>
      ),
    },
    {
      question: "What should I look for when choosing a sober living home?",
      answer: (
        <>
          <p>
            Look for a clean, safe place with clear rules. Pick homes with support services, regular drug testing, and a positive atmosphere for recovery.
          </p>
        </>
      ),
    },
    {
      question: "Do sober living homes in Texas have specific regulations?",
      answer: (
        <>
          <p>
            Yes, they must follow the Fair Housing Act and the Americans with Disabilities Act. They also need to meet various state standards in the Texas Administrative Code.
          </p>
        </>
      ),
    },
    {
      question: "How much does sober living in Texas cost?",
      answer: (
        <>
          <p>
            Prices vary based on location and amenities. Many homes offer sliding-scale payment options to help with different budgets.
          </p>
        </>
      ),
    },
    {
      question: "Can I work or go to school while living in a sober home?",
      answer: (
        <>
          <p>
            Yes! Most sober living homes encourage work or school. This helps you build a routine and get back to life outside.
          </p>
        </>
      ),
    },
    {
      question: "What amenities can I expect in Texas sober living housing?",
      answer: (
        <>
          <p>
            Amenities vary, but many homes offer furnished rooms, common areas, laundry, and Wi-Fi. Some may provide more comforts, depending on the facility.
          </p>
        </>
      ),
    },
    {
      question: "How do I find a good sober living facility in Texas?",
      answer: (
        <>
          <p>
            Do your research. Read reviews, visit homes, and ask addiction experts or local recovery groups for recommendations.
          </p>
        </>
      ),
    },
    {
      question: "Can I have visitors in a sober living home?",
      answer: (
        <>
          <p>
            It depends on the home. Some allow visitors during set hours, while others have stricter rules to maintain focus on recovery.
          </p>
        </>
      ),
    },
    {
      question: "Do sober living homes in Texas offer transportation?",
      answer: (
        <>
          <p>
            Some homes provide transportation, but not all. Be sure to ask about this when researching potential places to stay.
            <br/>
            <br/>
            <p>Finding a sober living home in Texas can be key to lasting recovery. Take it one day at a time. Use trusted resources, connect with others, and give yourself time to heal. You’re not alone—take the next step today.
            </p>
          </p>
        </>
      ),
    },
  ]; 

  return (
    <>
   <div className="container florida">
      {/* <FAQSchema /> */}
      <div className="faq-content py-0">
        <div className="faq-content-block py-4 mt-2">
          {/* <h1 className="faqs mb-0 text-center">
          Facilities For Sober Living in Texas
          </h1> */}
          <p>
          Starting over can be tough. Texas sober living homes can help you get back on your feet. They offer a safe and supportive place where you can focus on recovery. If you're ready to make a change, these homes could be the answer.
          </p>
        </div>
      </div>
    </div>
    
    <br/>
  
    <div className="container florida">
      <div className="faq-content">
        <div className="faq-content-block">
          <h2 className="faqs">Frequently Asked Questions About Sober Living in Texas</h2>
        </div>

        {faqData.map((faq, index) => (
          <div key={index} className="faq-content-block accordion-block">
            <div
              className={`accordion-header ${
                expandedIndex === index ? "expanded" : ""
              }`}
              onClick={() => toggleAccordion(index)}
            >
              <h3>{faq.question}</h3>
              <span className="icon"></span>
            </div>
            {expandedIndex === index && (
              <div className="accordion-content">
                <div className="inner-content">{faq.answer}</div>
              </div>
            )}
          </div>
        ))}
      </div>
    </div>

    </>
  );
};

export default TexasFaq;
