import React, { Component } from 'react';
import { Modal, Button } from 'react-bootstrap';
import ModalComponent from '../../components/modal/modal.component';
import { ReportCause } from '../../constants';
import { Formik, Field, Form } from 'formik';
import { livingAction } from '../../actions';
import { connect } from 'react-redux';
import { SpinnerContext } from '../../components/spinner/spinner.component';

class LivingReport extends Component {
  static contextType = SpinnerContext;
  _isMounted = false;
  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
      scrollTop: 0,
      showOther: false
    };
  }

  reportOptions = [
    {
      label: 'Inappropriate media',
      icon: 'media.svg',
      value: ReportCause.InappropriateMedia
    },
    {
      label: 'Inappropriate messages',
      icon: 'inappropriate.svg',
      value: ReportCause.InappropriateMessages
    },
    {
      label: "Home doesn't look like pictures",
      icon: 'home-dark.svg',
      value: ReportCause.ImagesMismatch
    },
    {
      label: 'Spam or scam',
      icon: 'spam-scam.svg',
      value: ReportCause.SpamScam
    },
    {
      label: 'Other',
      value: ReportCause.Other
    }
  ];

  componentDidMount() {
    this._isMounted = true;
    window.addEventListener('scroll', this.handleScroll, true);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
    this._isMounted = false;
  }

  handleScroll = () => {
    if (this._isMounted) {
      this.setState({
        scrollTop: Math.ceil(window.scrollY)
      });
    }
  };
  render() {
    const reportModal = () => {
      return (
        <div>
          <Formik
            initialValues={{
              cause: ReportCause.InappropriateMedia,
              text: ''
            }}
            onSubmit={async (values) => {
              const { soberLivingId } = this.props;
              this.props
                .reportCause({
                  soberLivingId,
                  ...values
                })
                .then(() => {
                  this.context.showMessage('Submitted complaint successfully!!!');
                  this.setState({ showModal: false });
                });
            }}
          >
            {({ values }) => (
              <Form>
                <Modal.Header>
                  <Modal.Title className="text-center"> Cause of Complaint</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <ul className="cause-complaint">
                    {this.reportOptions.map((option, index) => {
                      return (
                        <li key={index}>
                          <div className="cause-ttl">
                            <img src={option.icon && require(`../../assets/images/${option.icon}`)} alt={option.label} />
                            <span>{option.label}</span>
                          </div>
                          <div className="custom-control custom-radio">
                            <Field type="radio" id={option.value} value={option.value} name="cause" className="custom-control-input" />
                            <label className="custom-control-label" htmlFor={option.value}>
                              &nbsp;
                            </label>
                          </div>
                        </li>
                      );
                    })}
                  </ul>
                  {values.cause === ReportCause.Other && (
                    <div className="form-group">
                      <Field type="textarea" name="text" className="form-control" value={values.text} placeholder="Comments" required />
                    </div>
                  )}
                </Modal.Body>
                <Modal.Footer className="justify-content-end">
                  <Button variant="secondary" className="btn btn-outline text-dark" onClick={() => this.setState({ showModal: false })}>
                    Cancel
                  </Button>
                  <Button type="submit" className="btn btn-primary mr-3">
                    Report
                  </Button>
                </Modal.Footer>
              </Form>
            )}
          </Formik>
        </div>
      );
    };

    return (
      <>
        <a className={`info-ico cursor-pointer ${this.state.scrollTop > 50 ? ' fixed-mobile' : ''}`} onClick={() => this.setState({ showModal: true })}>
          <img src={require('../../assets/images/info.svg')} alt="info" />
        </a>
        {this.state.showModal && <ModalComponent show={this.state.showModal} component={reportModal} className="complaint-modal" />}
      </>
    );
  }
}
export default connect(
  ({ living }) => ({
    submitedReport: living.submitedReport
  }),
  livingAction
)(LivingReport);
