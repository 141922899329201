import React from "react";
import FAQSchema from "./FAQSchema";

const soberLivingheader = () => {
  return (
    <div className="container">
      {/* <FAQSchema /> */}
      <div className="faq-content py-0">
        <div className="faq-content-block py-4 mt-2">
          {/* <h1 className="faqs mb-0 text-center">
            Sober Living Homes
          </h1> */}
          <p>
            Discover a reliable landing pad on your road to recovery. Enjoy a
            network of sober living homes throughout the U.S. that offers
            comprehensive, compassionate, and expert care for sober recovery.
            These locations are conveniently located throughout the United
            States. They are designed for those hoping to recover from drug
            addiction, alcohol abuse, and more.
          </p>
        </div>
      </div>
    </div>
  );
};

export default soberLivingheader;
