import React, { useEffect, useState } from "react";
import BackButtonComponent from "../../../components/other/back-button.component";
import ReactHelmet from "../../../components/react-helmet/react-helmet";
import collapseIcon from "../../../assets/images/collapse.svg";
import { Link } from "react-router-dom";
import expandIcon from "../../../assets/images/expand.svg";
import { SpinnerContext } from "../../../components/spinner/spinner.component";
import checkMarkIcon from "../../../assets/images/checkmark_check_tick-green_bg.svg";
import cancleImg from "../../../assets/images/cancle.svg";
import infoOrangeIcon from "../../../assets/images/info-orange.svg";
import { chatActions, } from "../../../actions/chat.action";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { Modal } from "react-bootstrap";
import { livingAction } from "../../../actions";
export const ManageChat = () => {
  const dispatch = useDispatch();
  const livingstate = useSelector((state) => state?.chat.Fetched_SurveyList);
  const checkfacilitiesproornot = useSelector((state)=>state?.living?.hostLivings)
  const facilities = useSelector((state)=>state?.chat?.Fetched_SurveyList)

  const surveyList = useSelector((state) => state?.chat.surveyList);
  const surveyStatus = useSelector((state) => state?.chat.surveyStatus);
  const user = useSelector((state) => state?.auth?.user);
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [missingFields, setMissingFields] = useState([]);
  const contextType = React.useContext(SpinnerContext);
  const [openFacilityModel, setopenFacilityModel] = useState(false);
  const [overrideState, setoverrideState] = useState("");
  const [overrideModel, setoverrideModel] = useState(false);

  const [overrideMsg, setoverrideMsg] = useState([]);
  const [overrideFacility, setoverrideFacility] = useState([]);
  const [questioUpdateModel, setquestioUpdateModel] = useState(false);
  const [flag, setflag] = useState(false);
  const history = useHistory();
  const [surveyData, setSurveyData] = useState([
    {
      id: 1,
      question: "Which facility service are you seeking?",
      type: "multipleChoice",
      answers: { A: "Detox", B: "Residential", C: "IOP", D: "PHP" },
    },

    {
      id: 2,
      question: "",
      type: "multipleChoice",
      answers: { A: "", B: "", C: "", D: "" },
    },
    {
      id: 3,
      question: "",
      type: "multipleChoice",
      answers: { A: "", B: "", C: "", D: "" },
    },
    {
      id: 4,
      question: "",
      type: "multipleChoice",
      answers: { A: "", B: "", C: "", D: "" },
    },
    {
      id: 5,
      question: "",
      type: "multipleChoice",
      answers: { A: "", B: "", C: "", D: "" },
    },
  ]);

  const [selectedFacilities, setSelectedFacilities] = useState([]);
  useEffect(() => {
      dispatch(livingAction.fetchhostLivings())
      dispatch(chatActions.facilityList())
  }, []);


 function checkFaciliryProornot (){
          // Combine all facility lists from the state
          const allLists = [
            ...(checkfacilitiesproornot?.iopList || []),
            ...(checkfacilitiesproornot?.residential || []),
            ...(checkfacilitiesproornot?.php || []),
            ...(checkfacilitiesproornot?.detox || [])
          ];
          const foundPro = allLists?.some(
                    (item) =>  item?.subscriptionDetail?.subscriptionType === 'pro'
                  );
                
         return foundPro
 }

  const handleCheckboxChange = (event, facilityId, facilityName) => {
    const isChecked = event.target.checked;
    const selectedFacility = { id: facilityId, name: facilityName };

    if (isChecked) {
      const isContextTypeSelected = selectedFacilities.some(
        (facility) => facility.id === contextType.facilityId
      );

      setSelectedFacilities((prevFacilities) => {
        const updatedFacilities = isContextTypeSelected
          ? prevFacilities
          : contextType.facilityId != ""
          ? [
              ...prevFacilities,
              { id: contextType.facilityId, name: contextType.facilityName },
            ]
          : prevFacilities;
        return [...updatedFacilities, selectedFacility];
      });
    } else {
      setSelectedFacilities((prevFacilities) =>
        prevFacilities.filter((facility) => facility.id !== facilityId)
      );
    }
  };

  const getNonEmptyQuestions = (surveyData) => {
    return surveyData.filter(
      (question) =>
        question.question.trim() !== "" ||
        Object.values(question.answers).some((answer) => answer.trim() !== "")
    );
  };

  const mapQuestions = (nonEmptyQuestions) => {
    return nonEmptyQuestions.map((question, index) => {
      const questionObj = {
        question: question.question,
        answerType: question.type,
        position: index + 1,
      };
      if (question.type === "multipleChoice") {
        questionObj.options = Object.values(question.answers).filter(
          (answer) => answer !== ""
        );
      }
      return questionObj;
    });
  };

  const filterFacilityIds = (facilityIds, surveyIds) => {
    return facilityIds.filter((facilityId) => !surveyIds.includes(facilityId));
  };

  const preparePayload = (questions, facilityIds, overrideState, surveyIds) => {
    const filteredFacilityIds = filterFacilityIds(facilityIds, surveyIds);
    return {
      questions,
      facilityCenters:
        overrideState === "skip" ? filteredFacilityIds : facilityIds,
      override: overrideState === "skip" ? false : true,
    };
  };

  const handleUpdate = async (event) => {
    event.preventDefault();
    updateSurvey();
  };
  const updateSurvey = () => {
    const nonEmptyQuestions = getNonEmptyQuestions(surveyData);
    const questions = mapQuestions(nonEmptyQuestions);
    const facilityIds = Array.from(
      new Set([
        ...(selectedFacilities.length !== 0
          ? selectedFacilities.map((facility) => facility.id)
          : []),
        ...(contextType.facilityId !== "" ? [contextType.facilityId] : []),
      ])
    );
    if (overrideState !== "") {
      const surveyIds = surveyStatus?.data?.facilityWithTitle.map(
        (survey) => survey.id
      );
      const payload = preparePayload(
        questions,
        facilityIds,
        overrideState,
        surveyIds
      );
      setflag(true);
      dispatch(chatActions.updateSurvey(surveyList?.id, payload));
    } else {
      const payload = {
        questions,
        facilityCenters: facilityIds,
      };
      setflag(true);
      dispatch(chatActions.updateSurvey(surveyList?.id, payload));
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    submitForm();
  };

  const submitForm = async () => {
    const nonEmptyQuestions = getNonEmptyQuestions(surveyData);
    const questions = mapQuestions(nonEmptyQuestions);
    const facilityIds =
      selectedFacilities.length !== 0
        ? selectedFacilities.map((facility) => facility.id)
        : [`${contextType.facilityId}`];
    const payload = {
      questions,
      facilityCenters: facilityIds,
    };
    if (overrideState !== "") {
      const surveyIds = surveyStatus?.data?.facilityWithTitle.map(
        (survey) => survey.id
      );

      const payload = preparePayload(
        questions,
        facilityIds,
        overrideState,
        surveyIds
      );
      if (payload.facilityCenters.length != 0) {
        setflag(true);
        dispatch(chatActions.addingSurvey(payload));
      } else {
        setShowErrorModal(true);
        setMissingFields("Facility centers must contain at least one element");

        window.location.reload();
      }
    } else {
      setflag(true);
      dispatch(chatActions.addingSurvey(payload));
    }
  };

  useEffect(() => {
    if (overrideState != "") {
      surveyList?.id ? updateSurvey() : submitForm();
    }
  }, [overrideState]);

  useEffect(() => {
    if (surveyStatus?.data?.overrideError) {
      setoverrideMsg(surveyStatus?.data?.overrideError?.data);
      setoverrideFacility(surveyStatus?.data?.facilityWithTitle);
      setoverrideModel(true);
    } else {
      if (surveyStatus?.status === 200 && flag) {
        setopenFacilityModel(false);
        setoverrideModel(false);
        setquestioUpdateModel(true);
      }
    }
  }, [surveyStatus]);

  useEffect(() => {
    dispatch(chatActions.facilityList());
    handlePreviousfacilty();
    contextType.hideLoader();
  }, []);

  const handlePreviousfacilty = () => {
    if (contextType.facilityId) {
      dispatch(chatActions.fetchSurveyById(contextType?.facilityId));
    }
  };

  const updateSelectedFacilities = (idsToCheck, livingstate) => {
    idsToCheck.forEach((idToCheck) => {
      const matchingFacility = livingstate?.find(
        (facility) => facility?.id === idToCheck
      );
      if (matchingFacility) {
        setSelectedFacilities((prevFacilities) => [
          ...prevFacilities,
          { id: matchingFacility?.id, name: matchingFacility?.name },
        ]);
      }
    });
  };

  useEffect(() => {
    if (surveyList && Object.keys(surveyList).length !== 0) {
      updateSelectedFacilities(surveyList?.facilityCenters, livingstate);
      setSurveyData(
        surveyList?.questions?.map((question) => {
          const surveyQuestion = {
            id: question.position,
            question: question.question,
            type: question.answerType,
            answers: {},
          };

          if (question?.options) {
            surveyQuestion.answers = question.options.reduce(
              (acc, option, index) => {
                acc[String.fromCharCode(65 + index)] = option;
                return acc;
              },
              {}
            );
          }
          return surveyQuestion;
        }) || [
          {
            id: 1,
            question: "",
            type: "multipleChoice",
            answers: { A: "", B: "", C: "", D: "" },
          },
          {
            id: 2,
            question: "",
            type: "multipleChoice",
            answers: { A: "", B: "", C: "", D: "" },
          },
          {
            id: 3,
            question: "",
            type: "multipleChoice",
            answers: { A: "", B: "", C: "", D: "" },
          },
          {
            id: 4,
            question: "",
            type: "multipleChoice",
            answers: { A: "", B: "", C: "", D: "" },
          },
          {
            id: 5,
            question: "",
            type: "multipleChoice",
            answers: { A: "", B: "", C: "", D: "" },
          },
        ]
      );
    } else {
      setSurveyData([
        {
          id: 1,
          question: "Which facility service are you seeking?",
          type: "multipleChoice",
          answers: { A: "Detox", B: "Residential", C: "IOP", D: "PHP" },
        },

        {
          id: 2,
          question: "",
          type: "multipleChoice",
          answers: { A: "", B: "", C: "", D: "" },
        },
        {
          id: 3,
          question: "",
          type: "multipleChoice",
          answers: { A: "", B: "", C: "", D: "" },
        },
        {
          id: 4,
          question: "",
          type: "multipleChoice",
          answers: { A: "", B: "", C: "", D: "" },
        },
        {
          id: 5,
          question: "",
          type: "multipleChoice",
          answers: { A: "", B: "", C: "", D: "" },
        },
      ]);
      if (contextType.facilityId != "" && surveyList === undefined) {
        setSelectedFacilities([
          { id: contextType.facilityId, name: contextType.facilityName },
        ]);
      }
    }
  }, [surveyList]);

  const handleQuestionChange = (questionId, newQuestionText) => {
    if (newQuestionText.length <= 255) {
      setSurveyData((prevData) =>
        prevData.map((question) => {
          if (question.id === questionId) {
            return { ...question, question: newQuestionText };
          }
          return question;
        })
      );
    } else {
      setShowErrorModal(true);
      setMissingFields("Question cannot exceed 255 characters");
    }
  };

  const handleTypeChange = (questionId, newType) => {
    setSurveyData((prevData) =>
      prevData.map((question) => {
        if (question.id === questionId) {
          let updatedQuestion = { ...question, type: newType };
          if (newType === "multipleChoice") {
            updatedQuestion = {
              ...updatedQuestion,
              answers: { A: "", B: "", C: "", D: "" },
            };
          }
          return updatedQuestion;
        }
        return question;
      })
    );
  };

  const handleAnswerChange = (questionId, option, newAnswer) => {
    if (newAnswer.length > 100) {
      setShowErrorModal(true);
      setMissingFields("Answer cannot exceed 100 characters");
      return;
    }
    setSurveyData((prevData) =>
      prevData.map((question) => {
        if (question.id === questionId) {
          return {
            ...question,
            answers: { ...question.answers, [option]: newAnswer },
          };
        }
        return question;
      })
    );
  };

  const removeQuestion = (questionId) => {
    setSurveyData((prevData) => {
      const updatedData = prevData.filter(
        (question) => question.id !== questionId
      );

      return updatedData.map((question, index) => ({
        ...question,
        id: index + 1,
      }));
    });
  };

  const addQuestion = () => {
    if (surveyData.length < 20) {
      const newId = surveyData.length + 1;
      setSurveyData((prevData) => [
        ...prevData,
        {
          id: newId,
          question: "",
          type: "multipleChoice",
          answers: { A: "", B: "", C: "", D: "" },
        },
      ]);
    } else {
      setShowErrorModal(true);
      setMissingFields("You can only add a maximum of 20 questions");
    }
  };

  const handleRemoveFacility = (facilityId) => {
    const isContextFacility = selectedFacilities.some(
      (facility) => facility.id === contextType.facilityId
    );

    if (isContextFacility && facilityId === contextType.facilityId) {
      return;
    }
    setSelectedFacilities((prevFacilities) =>
      prevFacilities?.filter((facility) => facility?.id !== facilityId)
    );
  };

  const handleSubmitFacility = () => {
   const status = checkFaciliryProornot()
   if(status){
    setopenFacilityModel(false)
  } else{
    setopenFacilityModel(true)
  }
    const hasFilledQuestions = surveyData.some(
      (question) => question.question.trim() !== ""
    );

    const hasIncompleteQuestions = surveyData.some((question) => {
      const isQuestionFilled = question.question.trim() !== "";
      if (isQuestionFilled && question.type === "multipleChoice") {
        const hasInvalidOption = Object.values(question.answers).some(
          (answer) => answer.trim().length < 2 || answer.trim().length > 50
        );
        return hasInvalidOption;
      }
      return false;
    });

    if (!hasFilledQuestions) {
      setShowErrorModal(true);
      setMissingFields("Please fill at least one question before proceeding.");
    } else if (hasIncompleteQuestions) {
      setShowErrorModal(true);
      setMissingFields(
        "If a question is filled and its answer type is 'multipleChoice', all answer options must be filled and have a text length between 2 and 100 characters."
      );
    } else {
      setopenFacilityModel(true);
      document.body.classList.add("modal-open");
    }
  };

  const onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }

    const items = Array.from(surveyData);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);
    setSurveyData(items);
  };

  return (
    <div>
      <ReactHelmet
        title={"Taste Recovery - Chat"}
        description={
          "Taste Recovery is a free, nationwide service for finding safe, peer-reviewed sober living homes. Search hundreds of listings now for free."
        }
      />
      <section className="back manage-survey_section-back">
        <BackButtonComponent className="container" isBlack={true} />
      </section>
      <section className="manage-survey_section">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="manage-survey_header d-flex flex-column">
                <div className="manage-survey_button text-right">
                  <button
                    type="button"
                    className="btn btn-primary radius-20"
                    onClick={handleSubmitFacility}
                  >
                    Submit
                  </button>
                </div>
                <ul className="manage-survey_tabs d-flex align-items-center">
                  <li className="tab-item">
                    <Link
                      to="/chats"
                      className="tab-link"
                      style={{ cursor: "pointer" }}
                    >
                      Chat
                    </Link>
                  </li>
                  <li className="tab-item active">
                    {user?.role === "host" && (
                      <Link
                        to="/manageSurvey"
                        className="tab-link"
                        style={{ cursor: "pointer" }}
                      >
                        Manage Questionnaire
                      </Link>
                    )}
                  </li>
                </ul>
                <div className="manage-survey_tab-content">
                  <div className="manage-survey_question-wrapper d-flex flex-column">
                    <DragDropContext onDragEnd={onDragEnd}>
                      <Droppable droppableId="survey">
                        {(provided) => (
                          <div
                            {...provided.droppableProps}
                            ref={provided.innerRef}
                          >
                            {surveyData.map((question, index) => (
                              <Draggable
                                key={question.id.toString()}
                                draggableId={question.id.toString()}
                                index={index}
                              >
                                {(provided) => (
                                  <div
                                    ref={provided.innerRef}
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}
                                    className="manage-survey_question-container d-flex align-items-center"
                                  >
                                    <div
                                      className="manage-survey_question-block flex-fill"
                                      style={{ marginBottom: "16px" }}
                                    >
                                      <div className="manage-survey_question">
                                        <h4>Question {question.id}</h4>
                                        <input
                                          type="text"
                                          className="form-control"
                                          value={question.question}
                                          onChange={(e) =>
                                            handleQuestionChange(
                                              question.id,
                                              e.target.value
                                            )
                                          }
                                        />
                                      </div>
                                      <div className="manage-survey_question-option">
                                        <form className="add-sobar-living">
                                          <div className="row">
                                            <div className="col-md-12">
                                              <div className="form-group mb-0">
                                                <div className="dflex-form justify-content-start border-0 pb-0">
                                                  <div className="custom-control custom-radio">
                                                    <input
                                                      type="radio"
                                                      id={`multipleChoice-${question.id}`}
                                                      value="multipleChoice"
                                                      name={`answerType-${question.id}`}
                                                      className="custom-control-input"
                                                      checked={
                                                        question.type ===
                                                        "multipleChoice"
                                                      }
                                                      onChange={() =>
                                                        handleTypeChange(
                                                          question.id,
                                                          "multipleChoice"
                                                        )
                                                      }
                                                    />
                                                    <label
                                                      className="custom-control-label"
                                                      htmlFor={`multipleChoice-${question.id}`}
                                                    >
                                                      Multiple Choice
                                                    </label>
                                                  </div>
                                                  <div className="custom-control custom-radio">
                                                    <input
                                                      type="radio"
                                                      id={`yesOrNo-${question.id}`}
                                                      value="yesOrNo"
                                                      name={`answerType-${question.id}`}
                                                      className="custom-control-input"
                                                      checked={
                                                        question.type ===
                                                        "yesOrNo"
                                                      }
                                                      onChange={() =>
                                                        handleTypeChange(
                                                          question.id,
                                                          "yesOrNo"
                                                        )
                                                      }
                                                    />
                                                    <label
                                                      className="custom-control-label"
                                                      htmlFor={`yesOrNo-${question.id}`}
                                                    >
                                                      Yes / No
                                                    </label>
                                                  </div>
                                                  <div className="custom-control custom-radio">
                                                    <input
                                                      type="radio"
                                                      id={`text-${question.id}`}
                                                      value="text"
                                                      name={`answerType-${question.id}`}
                                                      className="custom-control-input"
                                                      checked={
                                                        question.type === "text"
                                                      }
                                                      onChange={() =>
                                                        handleTypeChange(
                                                          question.id,
                                                          "text"
                                                        )
                                                      }
                                                    />
                                                    <label
                                                      className="custom-control-label"
                                                      htmlFor={`text-${question.id}`}
                                                    >
                                                      Text
                                                    </label>
                                                  </div>
                                                </div>
                                              </div>

                                              {question.type ===
                                                "multipleChoice" && (
                                                <div className="multiple-choice_answer d-flex align-items-center mt-3 mt-md-4">
                                                  {Object.entries(
                                                    question.answers
                                                  ).map(([key, value]) => (
                                                    <div
                                                      key={key}
                                                      className="form-group d-flex align-items-center mb-0"
                                                    >
                                                      <label className="m-0">
                                                        {key}
                                                      </label>
                                                      <input
                                                        type="text"
                                                        className="form-control m-0"
                                                        value={value}
                                                        onChange={(e) =>
                                                          handleAnswerChange(
                                                            question.id,
                                                            key,
                                                            e.target.value
                                                          )
                                                        }
                                                        placeholder={`Enter answer ${key}`}
                                                      />
                                                    </div>
                                                  ))}
                                                </div>
                                              )}
                                            </div>
                                          </div>
                                        </form>
                                      </div>
                                    </div>
                                    <div className="manage-survey_question-action">
                                      {index === surveyData.length - 1 ? (
                                        <button
                                          type="button"
                                          className="btn-icon-only"
                                          onClick={addQuestion}
                                        >
                                          <img
                                            src={expandIcon}
                                            className="img-fluid"
                                            alt="expandIcon"
                                          />
                                        </button>
                                      ) : (
                                        <button
                                          type="button"
                                          className="btn-icon-only"
                                          onClick={() =>
                                            removeQuestion(question.id)
                                          }
                                        >
                                          <img
                                            src={collapseIcon}
                                            className="img-fluid"
                                            alt="collapseIcon"
                                          />
                                        </button>
                                      )}
                                    </div>
                                  </div>
                                )}
                              </Draggable>
                            ))}
                            {provided.placeholder}
                          </div>
                        )}
                      </Droppable>
                    </DragDropContext>

                    <div className="d-flex d-md-none flex-wrap">
                      {surveyData.length < 20 && (
                        <button
                          type="button"
                          className="btn btn-default d-flex align-items-center justify-content-center mb-2 mr-2"
                          onClick={addQuestion}
                        >
                          <span>+</span> Add
                        </button>
                      )}

                      {surveyData.length > 1 && (
                        <button
                          type="button"
                          className="btn btn-default d-flex align-items-center justify-content-center mb-2"
                          onClick={() => removeQuestion(surveyData.length)}
                        >
                          <span>-</span> Remove
                        </button>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <div
        className={`modal upgrade-plan cancel-subscription-modal choose-facility-modal fade ${
          openFacilityModel ? "show" : ""
        }`}
        id="exampleModal"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden={!openFacilityModel}
      >
        { (selectedFacilities?.length===0 && facilities?.length === 0 || checkFaciliryProornot() === false) ? (
           <div className="modal upgrade-plan cancel-subscription-modal fade show" id="exampleModal" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
           <div className="modal-dialog modal-dialog-centered" role="document">
               <div className="modal-content">
                   <div className="upgrade-plan-content">
                       <span className="loading-msg">Upgrade your facility to Pro to prequalify your potential leads</span>
                   </div>
                   <div className="modal-footer">
                       <button type="submit" className="modal-btn" onClick={() => {
                    document.body.classList.remove("modal-open");
                    setopenFacilityModel(false);
                    // setSelectedFacilities([]);
                    handlePreviousfacilty();
                    
                    dispatch(chatActions.facilityList());
                  }}>Cancel</button>
                   </div>
               </div>
           </div>
       </div>
        ) : (
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content">
              <div className="upgrade-plan-content">
                <h3 className="text-left" style={{ textTransform: "none" }}>
                  Choose facility centers
                </h3>
                <div className="facility-list">
                  {(selectedFacilities?.length != 0 && checkFaciliryProornot () === true) && (
                    <div className="selected-facility-wrapper active d-none d-md-flex align-items-center flex-wrap">
                      {selectedFacilities

                        .filter(
                          (facility, index, self) =>
                            index ===
                            self?.findIndex((f) => f?.id === facility?.id)
                        )
                        .map((facility, index) => (
                          <div
                            className="selected-facility-item d-flex align-items-center"
                            key={index}
                          >
                            <span>{facility.name}</span>
                            <a
                              className="d-flex align-items-center"
                              onClick={() => handleRemoveFacility(facility.id)}
                              style={{ cursor: "pointer" }}
                            >
                              <img
                                src={cancleImg}
                                className="img-fluid"
                                alt="Remove"
                              />
                            </a>
                          </div>
                        ))}
                    </div>
                  )}

                  {/* Display selected facilities */}
                  <form action="#">
                    <div className="taste-recovery-form add-sobar-living">
                      <div className="row">
                        <div className="col-md-12">
                          <div className="form-group checkbox_wrapper mb-0">
                            <div className="dflex-form flex-column border-0 p-0">
                              {livingstate?.map((facility, facilityIndex) => (
                                <div
                                  key={`${facilityIndex}`}
                                  className="custom-control custom-checkbox w-100"
                                >
                                  <input
                                    name={`facilityCenter${facilityIndex}`}
                                    type="checkbox"
                                    className="custom-control-input"
                                    id={`facilityCenter${facilityIndex}`}
                                    value="true"
                                    onChange={(event) =>
                                      handleCheckboxChange(
                                        event,
                                        facility?.id,
                                        facility?.name
                                      )
                                    }
                                    checked={
                                      contextType?.facilityId ===
                                        facility?.id ||
                                      selectedFacilities.some(
                                        (selectedFacility) =>
                                          selectedFacility?.id === facility?.id
                                      )
                                    }
                                    disabled={
                                      contextType?.facilityId === facility?.id
                                    }
                                  />
                                  <label
                                    className="custom-control-label"
                                    htmlFor={`facilityCenter${facilityIndex}`}
                                  >
                                    {facility?.name}
                                  </label>
                                </div>
                              ))}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="modal-btn"
                  onClick={() => {
                    document.body.classList.remove("modal-open");
                    setopenFacilityModel(false);
                    // setSelectedFacilities([]);
                    handlePreviousfacilty();
                    dispatch(chatActions.facilityList());
                  }}
                >
                  CANCEL
                </button>

                {surveyList?.id ? (
                  <button
                    type="submit"
                    className="modal-btn confirm-btn"
                    onClick={handleUpdate}
                  >
                    Update
                  </button>
                ) : (
                  <button
                    type="submit"
                    className="modal-btn confirm-btn"
                    onClick={handleSubmit}
                  >
                    SUBMIT
                  </button>
                )}
              </div>
            </div>
          </div>
        )}
      </div>

      <Modal
        centered
        show={showErrorModal}
        style={{
          position: "fixed",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: "auto",
          color: "#FF5F5F",
          alignItems: "center",
        }}
        className="spinner-taste"
        onHide={() => {}}
      >
        <img
          src={require("../../../assets/images/cross-cancel.png")}
          width="50"
          heigth="50"
          alt="Error!"
          onClick={() => setShowErrorModal(false)}
        />
        <span className="loading-msg">{missingFields}</span>
      </Modal>
      {/* Updated successfully modal */}
      <div
        className={`modal upgrade-plan cancel-subscription-modal  updated-successfully-modal fade ${
          questioUpdateModel ? "show" : ""
        }`}
        id="exampleModal"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden={!questioUpdateModel}
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="upgrade-plan-content">
              <img src={checkMarkIcon} className="img-fluid" alt="checkMarkIcon" />
              <span className="loading-msg d-block">
                Survey of questions updated successfully
              </span>
            </div>
            <div className="modal-footer">
              <button
                type="submit"
                className="modal-btn confirm-btn"
                onClick={() => {
                  setflag(false);
                  setquestioUpdateModel(false);
                  history.push(`/profile`);
                }}
              >
                OK
              </button>
            </div>
          </div>
        </div>
      </div>
      {/* Already updated modal */}
      <div
        className={`modal upgrade-plan cancel-subscription-modal  updated-successfully-modal fade ${
          overrideModel ? "show" : ""
        }`}
        id="exampleModal"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden={!overrideModel}
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="upgrade-plan-content">
              <img src={infoOrangeIcon} className="img-fluid" alt="infoOrangeIcon" />
              <span className="loading-msg d-block">
                {overrideMsg?.map((message, index) => (
                  <span key={index}>{message}</span>
                ))}
              </span>
              <span className="loading-msg d-block">
                <h5 style={{ marginTop: "5px" }}>Facility name:</h5>
                {selectedFacilities
                  .filter((item1) =>
                    overrideFacility.some(
                      (item2) =>
                        item1.id === item2.id && item1.name === item2.name
                    )
                  )
                  ?.map((facility, index) => (
                    <p key={index}>Name: {facility.name}</p>
                  ))}
              </span>
            </div>
            <div className="modal-footer">
              <button
                type="submit"
                className="modal-btn"
                onClick={() => {
                  setoverrideState("skip");
                }}
              >
                SKIP
              </button>
              <button
                type="submit"
                className="modal-btn confirm-btn"
                onClick={() => {
                  setoverrideState("override");
                }}
              >
                OVER-RIDE
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
