import React, { Component } from "react";
import imgAbout from "../../assets/images/img-about.jpg";
import ReactHelmet from "../../components/react-helmet/react-helmet";
class AboutUs extends Component {
  render() {
    return (
      <>
        <ReactHelmet
          title={"Taste Recovery - About Us"}
          description={
            "Taste Recovery is a free, nationwide service for finding safe, peer-reviewed sober living homes. Search hundreds of listings now for free."
          }
        />
        <section
          className={`container savehome-wrp setting-list pb-5 pb-lg-0 ${
            this.props.location.pathname === "/settings/about-us" ? "p-0" : ""
          }`}
          id="eight"
        >
          <h3 className="main-title title-decoration">About </h3>
          <img className="img-fluid" src={imgAbout} alt="imgAbout"></img>
          <div className="saved-home-wrappper mt-4">
            <div className="cms-wrp text-justify">
              <p>
                Taste Recovery was founded to support those seeking a safe
                refuge and a new start. The definition of taste is to discover
                through experience. With knowledge of what types of treatment
                facilities are out there, we hope to give you the best options
                to rediscover yourself and give you the best chance at finding
                long term sobriety.
              </p>
              <p>
                There is a lack of resources to help those looking to start a
                solid foundation in early recovery. We have heard the
                desperation from addicts, alcoholics, and their fearful loved
                ones. Early sobriety is difficult and is a time when addicts are
                at their most vulnerable. It is imperative that there is a
                trusted resource where individuals can go to find treatment that
                is safe, secure, and supportive as they launch their recovery.
              </p>
              <p>
                Taste Recovery wants to shine a light on this industry in a
                positive way. We hope to highlight the professionals that are
                doing everything in their power to ensure a safe, stable, and
                sober environment, while providing structure and support to
                those that choose their facility to get well.
              </p>
              <p>
                Taste Recovery offers those searching for treatment an
                opportunity to find a facility that best fits their individual
                needs. We believe that this will benefit each individual’s
                recovery by providing a new community that offers confidence and
                security. Recovery transcends when addicts and alcoholics find
                their sober tribe. We can’t do this alone. It takes a village, a
                home, a tribe, a community. Let us be a part of your journey as
                you trudge the road to happy destiny.
              </p><br/>
              <h4 className="meet-our-ceo"> Meet our CEO</h4>
              <p className="meet-out-ceo">
              <b>Brianna Nowlan</b> is the CEO and Founder of <b>Taste Recovery</b>, <b>Camp Sober Fest</b>, and <b>Sober Disclosure</b>, with over 9 years of personal experience in recovery. Having navigated through treatment centers herself, Brianna deeply understands the challenges individuals face when seeking safe and effective recovery options. This firsthand experience inspired her to create <b>Taste Recovery</b>, a platform born out of a desire to offer a better way to search for sober living options and treatment facilities.
              </p>
              <p className="meet-out-ceo"> What started in 2020 as a sober living listings app has quickly evolved into a comprehensive resource, allowing individuals to search for treatment centers in the same way they would for restaurants or vacation spots on Yelp or TripAdvisor. Brianna’s vision has always been to make it easier for people to find the right care, with the safety, support, and accountability they deserve. </p>
             <p className="meet-out-ceo">
             In addition to her work with <b>Taste Recovery</b>, Brianna’s passion project, <b>Camp Sober Fest</b>, is a reflection of her dedication to helping others in recovery. What began as a small two-day retreat in Big Bear has grown into a transformative five-day retreat in the Sequoia National Forest. The retreat provides a hands-on experience for those in recovery, fostering a supportive community where accountability is key to staying sober.
             </p>
             <p className="meet-out-ceo">
             Brianna also hosts the <b>Sober Disclosure</b> podcast, alongside co-host Jimmy, where they interview individuals with long-term sobriety and focus specifically on their <b>first year of recovery</b>. This often-challenging year is where many people struggle, convinced they’ll relapse or that recovery is impossible. The podcast highlights that while setbacks are common, the only rule is to never drink or use drugs, no matter what. Through <b>Sober Disclosure</b>, Brianna and Jimmy share real stories of resilience, offering hope and direction to those facing their own recovery journey. The podcast has become an incredible platform for spreading the message of hope and sobriety wealth to the world.
             </p>
             <p className="meet-out-ceo">
             Brianna holds a <b>marketing degree from the Fashion Institute of Design & Merchandising (FIDM)</b>, along with studies at <b>San Francisco State University</b> and <b>Macquarie University in Sydney, Australia</b>. Additionally, she has completed numerous courses on <b>Ethics in the treatment field</b>, ensuring she stays informed on the ever-evolving landscape of recovery care.
             </p>
             <p className="meet-out-ceo">
             Through <b>Taste Recovery</b>, <b>Camp Sober Fest</b>, and <b>Sober Disclosure</b>, Brianna strives to educate individuals on the importance of thoroughly researching treatment centers and addiction recovery options. She has seen firsthand the devastating effects of treatment centers that don’t have the best interests of their clients at heart, losing many friends to unsafe practices. Her mission is to prevent others from facing the same fate by providing trusted, insightful resources on addiction, recovery, and how to best support loved ones through the process.
             </p>
            </div>
          </div>
        </section>
      </>
    );
  }
}

export default AboutUs;
