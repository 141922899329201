import React, { useState, useEffect, useRef } from "react";
import pinImg from "../../assets/images/ic_pin_small.svg";
import starImg from "../../assets/images/star-24px.svg";
import NoImage from "../../assets/images/no-image.svg";
import Slider from "react-slick";
import { Link } from "react-router-dom";
import tickMarkIcon from "../../assets/images/tick-mark.svg";
import SliderNextArrow from "../../components/slider/imageSliderNext";
import SliderPrevArrow from "../../components/slider/imageSliderPrev";
import { useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import accountImgMobile from "../../assets/images/account-mobile.png";

const FacilityList = ({ facilities, total, currentPage, setCurrentPage, recordsPerPage }) => {
  const history = useHistory();

  const settings1 = {
    dots: true,
    infinite: true,
    speed: 300,
    autoplay: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <SliderNextArrow />,
    prevArrow: <SliderPrevArrow />,
  };

  const handleShowMore = () => {
    setCurrentPage(currentPage + 1);
  };

  return (
    <>
      <div className="container">
        {facilities && facilities?.length !== 0 ? (
          <div className="row">
            {facilities?.map((ele, id) => {
              return (
                <div className="col-md-6 col-xl-4" key={id}>
                 <Link
                  className="text-decoration-none"
                  to={{ pathname: `/home/centers/view/${ele?.id}` }}
                  >
                    <div className="facility-list-item">
                      <div className="img-slider position-relative">
                        {ele?.subscriptionType === "pro" ||
                        ele?.subscriptionDetail?.subscriptionType === "pro" ? (
                          <Slider {...settings1}>
                            {ele?.photos?.length > 0 ? (
                              ele?.photos?.map((item, id) => {
                                return (
                                  <img
                                    src={item?.path}
                                    className="img-fluid facility-img"
                                    alt={ele?.name}
                                    key={id}
                                  />
                                );
                              })
                            ) : (
                              <img
                                src={NoImage}
                                className="img-fluid facility-img"
                                alt="NoImage"
                              />
                            )}
                          </Slider>
                        ) : (
                          <Slider {...settings1}>
                            {ele?.photos?.length > 0 ? (
                              <img
                                src={ele?.photos[0]?.path}
                                className="img-fluid facility-img"
                                alt={ele?.name}
                              />
                            ) : (
                              <img
                                src={NoImage}
                                className="img-fluid facility-img"
                                alt={ele?.name}
                              />
                            )}
                          </Slider>
                        )}
                        {/* {(ele?.preSalePlanType === "pro" || ele?.subscriptionDetail?.subscriptionType === "pro") && <span className="subscription-msg unsubscribed">Featured</span>} */}
                        {ele?.isPPCSubscribed && (
                          <span className="promote-ad-msg">Ad</span>
                        )}
                      </div>
                      <div className="card-body">
                        <div className="card-heading_wraper d-flex align-items-center justify-content-between">
                          <div className="card-heading">
                            {ele?.name?.length > 30
                              ? ele?.name
                                  ?.slice(0, 30)
                                  ?.split(" ")
                                  ?.map(
                                    (word) =>
                                      word.charAt(0).toUpperCase() +
                                      word.slice(1).toLowerCase()
                                  )
                                  .join(" ") + "...."
                              : ele?.name
                                  ?.split(" ")
                                  .map(
                                    (word) =>
                                      word.charAt(0).toUpperCase() +
                                      word.slice(1).toLowerCase()
                                  )
                                  .join(" ")}
                            {ele?.isVerified && (
                              <img
                                src={tickMarkIcon}
                                className="img-fluid"
                                alt={ele?.name}
                              />
                            )}
                          </div>
                          {(ele?.isSubscribed || ele?.isPreSaleSubscribed) && (
                            <span className="location align-items-center">
                              <img
                                src={starImg}
                                className="img-fluid m-0"
                                alt="starImg"
                              />
                              {ele?.rating?.value !== 0 ? (
                                <>
                                  <span>{ele?.rating?.value.toFixed(1)}</span>
                                </>
                              ) : (
                                <>
                                  <span>0.0</span>
                                </>
                              )}
                            </span>
                          )}
                        </div>
                        <span className="location">
                          <img src={pinImg} className="img-fluid" alt="location" />
                          {ele?.geocodingResult?.cityName}
                          {", "}
                          {ele?.geocodingResult?.stateShort}
                          {", "}
                          {ele?.geocodingResult?.country}
                        </span>
                      </div>
                    </div>
                  </Link>
                </div>
              );
            })}

           {facilities.length < total && (
            <div className="col-md-12 mt-3">
              <p className="d-none d-md-flex flex-column flex-md-row justify-content-center">
                Continue exploring facilities
              </p>
              <div className="d-flex flex-column flex-md-row justify-content-center mt-sm-3">
                <button
                  type="button"
                  className="btn btn-primary radius-20"
                  onClick={handleShowMore}
                >
                  Show more
                </button>
              </div>
            </div>
          )}
          </div>
        ) : (
          <div className="col-md-12">
            <div className="no-facilities">
              <p>NO FACILITIES LISTED…YET!</p>
              <img src={accountImgMobile} className="img-fluid" alt="accountImgMobile" />
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default FacilityList;
