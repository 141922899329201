import React, { Component } from "react";
import mcImg from "../../assets/images/mc.svg";
import visaImg from "../../assets/images/visa.svg";
import ovalRight from "../../assets/images/oval-right.png";
import { connect } from "react-redux";
import { paymentActions } from "../../actions";
import { SpinnerContext } from "../../components/spinner/spinner.component";
import { Modal } from "react-bootstrap";
class PaymentCards extends Component {
  static contextType = SpinnerContext;
  static defaultProps = {
    selectedCard: undefined,
    onSelectCard: (id) => {},
  };
  state = {
    showErrorPopup: false, // Modal state
    contentdata: null, // Modal content
  };
  componentDidMount() {
    if (!this.props.isFetching) {
      this.context.showLoader("Get Cards...");
      this.props.getCards().finally(() => this.context.hideLoader());
    }
  }

  handleShowMessage = (card) => {
    if (
      card?.blocked &&
      window?.location?.pathname != "/settings/saved-cards"
    ) {
      this.setState({
        showErrorPopup: true,
        contentdata: {
          error:
            "Payment cannot be processed with this card as it has expired.",
        },
      });
      this.props.onSelectCard(null);
    } else {
      this.props.onSelectCard(card);
    }

    setTimeout(() => {
      this.setState({
        showErrorPopup: false,
        contentdata: null,
      });
    }, 3000);
  };

  render() {
    return (
      <>
        {this.props.cards.map((card, index) => {
          return (
            <div className="row mt-4" key={index}>
              <div className="col-md-6">
                <div
                  className="card-view cursor-pointer"
                  onClick={() => {
                    this.handleShowMessage(card);
                  }}
                >
                  <div className="card-top">
                    <img
                      src={card.cardBrand === "visa" ? visaImg : mcImg}
                      alt="visa"
                    />
                    {this.props.selectedCard === card.id && (
                      <div className="selected-card">
                        <img
                          src={
                            !card?.blocked ||
                            window?.location?.pathname ===
                              "/settings/saved-cards"
                              ? ovalRight
                              : null
                          }
                          alt="saved-cards"
                          // Handle click for blocked card
                        />
                      </div>
                    )}
                  </div>
                  <div className="card-number">
                    <span className="dots">. . . .</span>
                    <span className="dots">. . . .</span>
                    <span className="dots">. . . .</span>
                    <span className="num">{card.last4}</span>
                  </div>
                  <div className="card-bottom">
                    <div className="card-holder">
                      <span className="ttl">Cardholder</span>
                      <span className="val"> {card.cardHolderName}</span>
                    </div>
                    <div className="card-expiry">
                      <span className="ttl">Expires end</span>
                      <span className="val">
                        {card.expirationMonth}/{card.expirationYear}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          );
        })}
        {this.props.cards.length === 0 && !this.props.isFetching && (
          <div className="no-records">
            <h2>OOPS.. NO CARDS ADDED</h2>
            <p>Your credit cards will show up here</p>
          </div>
        )}

        {this.state.showErrorPopup && (
          <Modal
            centered
            show={this.state.showErrorPopup}
            onHide={() => {}}
            className="upgrade-plan cancel-subscription-modal updated-successfully-modal"
            style={{ background: "transparent" }}
          >
            <div className="upgrade-plan-content d-flex flex-column align-items-center">
              <span className="loading-msg">
                {this.state.contentdata.error}
              </span>
            </div>
          </Modal>
        )}
      </>
    );
  }
}

export default connect(
  ({ payment }) => ({
    isFetching: payment.fetchingCards,
    cards: payment.cards,
    error: payment.error,
  }),
  paymentActions
)(PaymentCards);
