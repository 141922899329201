import React, { useState } from "react";
const Arizonafaq = () => {
  const [expandedIndex, setExpandedIndex] = useState(null);

  const toggleAccordion = (index) => {
    setExpandedIndex(expandedIndex === index ? null : index); // Close if it's already expanded, else open
  };

  const faqData = [
    {
      question: "Support for Various Addictions",
      answer: (
        <>
          <p className="mb-2">
          Whether you are struggling with alcohol abuse or drug addiction, all are welcome. Arizona sober living communities can help you break bad habits with the right level of support and structure.
          <br/>  <br/>
          These locations are strictly substance-free, so the temptation of use is rendered moot. Some locations may even employ support mechanisms to help residents while they are off-premises. Remember, the greater your support network the less likely you are to fall!
          </p>
          </>
      ),
    },
    {
      question: "Support for Family & Friends",
      answer: (
        <>
          <p className="mb-2">
          We know the journey to wellness is never done alone. That’s why we compile all of the most <a href="https://www.tasterecovery.com/resources/recovery-resources/" target="_blank">helpful resources</a> we can provide. When you’re better informed about your loved one, you can aid in their recovery process.
          <br/> <br/>
          Help your loved one develop healthy lifestyle choices. Allow Taste Recovery to aid in that journey.
          </p>
          </>
      ),
    },
    {
        question: "Find Your Perfect Community",
        answer: (
          <>
            <p className="mb-2">
            If you are ready to embrace sober living in Arizona, sober living houses in Arizona are the ticket. No two are alike, so browse various locations above to discover the community that best supports your goals.
            </p>
            </>
        ),
      },
  ];

  return (
    <div className="container" style={{ marginBottom: "200px" }}>
      <div className="faq-content-block py-4 mt-2">
        <div className="faq-content-block">
        </div>

        <p className="mb-2">
        Support a better livelihood, thanks to sober living houses in Arizona, as promoted by Taste Recovery. With the right foundation, the right environment, and the right support network, anything is possible. You truly can get the help you need and begin the path toward a life free of addiction.
        <br/><br/>
        Arizona sober living homes offer a safe and vice-free setting for you to focus on what matters. Enjoy a comfortable space with like-minded fellow residents. Everyone around you is here for one reason and one reason only: betterment.
        <br/><br/>
        Sober living homes in Arizona offer structured, supportive settings. Each location comes with distinct curfews, house rules, and requirements to keep residents safe. And each is specifically designed to help you leave your addictions behind as you begin a wellness-focused transformation.
        <br/><br/>
        Though the transition can be difficult at first, often Arizona sober living home residents find their footing quickly. Soon, lives can revolve around positive mental health and intentional fulfillment. And the residents and staff at Arizona sober living facilities are there to support you.
        <br/><br/>
        Sober living homes in Arizona as seen on Taste Recovery are available across the state. This includes popular locations in Phoenix, Scottsdale, and more. Each of the sober living homes in Arizona offers a tasteful setting and a friendly community that surrounds it.
        <br/><br/>
        Begin your journey toward a healthier life in peace and serenity. With homes specifically designed for sober living in Arizona, you’re sure to find the balance you’re looking for.
        <br/><br/>
        </p>

        <p className="mb-2"></p>


        {faqData.map((faq, index) => (
          <div key={index} className="faq-content-block accordion-block">
            <div
              className={`accordion-header ${
                expandedIndex === index ? "expanded" : ""
              }`}
              onClick={() => toggleAccordion(index)}
            >
              <h3>{faq.question}</h3>
              <span className="icon"></span>
            </div>
            {expandedIndex === index && (
              <div className="accordion-content">
                <div className="inner-content">{faq.answer}</div>
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default Arizonafaq;
