import React, { useState } from "react";
import FAQSchemaflorida from "./FAQSchemaflorida";

const FAQItem = ({ faq, isExpanded, onToggle }) => (
  <div className="faq-content-block accordion-block">
    <div
      className={`accordion-header ${isExpanded ? "expanded" : ""}`}
      onClick={onToggle}
    >
      <h3>{faq.question}</h3>
      <span className="icon"></span>
    </div>
    {isExpanded && (
      <div className="accordion-content">
        <div className="inner-content">{faq.answer}</div>
      </div>
    )}
  </div>
);

const Floridafaq = () => {
  const [expandedIndex1, setExpandedIndex1] = useState(null);
  const [expandedIndex2, setExpandedIndex2] = useState(null);

  const toggleAccordion1 = (index) => {
    setExpandedIndex1(expandedIndex1 === index ? null : index);
  };

  const toggleAccordion2 = (index) => {
    setExpandedIndex2(expandedIndex2 === index ? null : index);
  };

  const faqData1 = [
    {
      question: "What Are Sober Living Homes in Florida?",
      answer: (
        <>
          <p className="mb-2">
          Sober living homes are places where people can live after addiction treatment. They provide support during the transition back to everyday life. These homes offer a structured environment free from alcohol and drugs.
          <br/> <br/>
          Sober living homes aren't treatment centers. They act as a middle step for those who need extra support on their recovery journey.
          <br/> <br/>
           In Florida, they are especially popular. The state offers a strong recovery network and a calming environment. Residents can rebuild their lives while avoiding triggers found in other places.
          </p>
          <h3>Benefits and Structure</h3>
          <p>Living in a sober home brings more than just a roof over your head. You'll find accountability through clear rules and support from others like you. These homes also help you build important life skills. Shared spaces and daily routines foster connection while keeping you balanced and focused.</p>
          <h3>Rules and Regulations</h3>
          <p>Sober living homes have simple rules. Residents must stay sober, attend house meetings, and complete chores. In Florida, these homes don’t need a special license unless they provide addiction treatment.</p>
          
        </>
      ),
    },
    {
        question: "FARR Certification and Its Importance",
        answer: (
          <>
            <p>
            In Florida, maintaining high standards for sober living homes is essential. FARR (Florida Association of Recovery Residences) certification plays a key role. This process ensures homes meet strict safety and recovery guidelines. They classify residences into levels, ranging from peer-run setups to highly structured environments.
            </p><br/>
            <p>
            Unlike a state license, FARR certification is voluntary but highly valued. It aids in referrals and assures quality for residents.
            </p><br/>
            <p>
            Certified homes follow clear guidelines, such as fostering a sober and ethical environment. They provide safe, well-maintained housing free from substances. With FARR certification, you can trust the home has passed a thorough review.
            </p>
          </>
        ),
      },
      {
        question: "Finding a Sober Living Home in Florida",
        answer: (
          <>
            <p>
            Finding the right sober living home in Florida can feel overwhelming, but you’re not alone. Start by exploring the properties listed here to see what fits your needs. Each home offers a supportive environment for your recovery journey. Take the first step and browse now—your next chapter starts here.
            </p>
          </>
        ),
      },
  ];

  const faqData2 = [
    {
      question: "What Can I Expect with Costs and Insurance?",
      answer: (
        <>
          <p className="mb-2">
          Sober living homes vary in price. Monthly costs usually cover rent, utilities, and shared expenses. Some homes accept insurance, while many require private payment
           </p>      
        </>
      ),
    },
    {
        question: "What is the Average Duration of Stay?",
        answer: (
          <>
            <p>
            There's no set timeline for your stay. Most residents stay three to twelve months, depending on their needs. The goal is to leave feeling confident and prepared for independence.
            </p>
          </>
        ),
      },
  ];

  return (
    <>
   <div className="container florida">
   <FAQSchemaflorida />
      {/* <FAQSchema /> */}
      <div className="faq-content py-0">
        <div className="faq-content-block py-4 mt-2">
          {/* <h1 className="faqs mb-0 text-center">
          Facilities For Sober Living in Florida
          </h1> */}
          <p>
          Recovering from addiction is a life-changing journey. Sober living houses in Florida offer a safe and supportive space. They help bridge the gap between treatment and daily life. 
          <br/>
          <br/>
          These homes promote accountability, stability, and a strong sense of community. That support can truly make a difference.
          <br/>
          </p>
        </div>
      </div>
    </div>

    <br/>
    
    <div className="container florida">
      <div className="faq-content">
        <div className="faq-content-block">
          <h2 className="faqs">Taste Recovery</h2>
        </div>
          {faqData1.map((faq, index) => (
            <FAQItem
              key={`faq1-${index}`}
              faq={faq}
              isExpanded={expandedIndex1 === index}
              onToggle={() => toggleAccordion1(index)}
            />
          ))}
        </div>
      </div>

      <br />

      <div className="container florida">
      <div className="faq-content">
        <div className="faq-content-block">
          <h2 className="faqs">Frequently Asked Questions About Sober Living in Florida</h2>
          <p>Here are the questions commonly asked about house tours:</p>
        </div>
          {faqData2.map((faq, index) => (
            <FAQItem
              key={`faq2-${index}`}
              faq={faq}
              isExpanded={expandedIndex2 === index}
              onToggle={() => toggleAccordion2(index)}
            />
          ))}
        </div>
      </div>

      <div className="container florida">
      {/* <FAQSchema /> */}
      <div className="faq-content py-0">
        <div className="faq-content-block py-4 mt-2">
        <h2 className="faqs">
           Florida Sober Living Homes
          </h2>
          <p>
          Finding sober living in Florida means choosing hope, support, and a fresh start. Take the next step toward a brighter future today. Search for your ideal sober living home and begin your journey to lasting recovery.
          </p>
        </div>
      </div>
    </div>
    </>
  );
};

export default Floridafaq;
