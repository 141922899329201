import React, { Component } from "react";
import { connect } from "react-redux";
import { authAction } from "../../actions";
import { Field, Form, Formik } from "formik";
import { Toast } from "react-bootstrap";
import { SpinnerContext } from "../../components/spinner/spinner.component";
import * as Yup from "yup";
import { Modal } from "react-bootstrap";
import { isEmpty } from "lodash";
import imgAbout from "../../assets/images/img-about.jpg";
import imgHome from "../../assets/images/home1.svg";
import imgEmail from "../../assets/images/email1.svg";
import imgPhone from "../../assets/images/phone-call.svg";
import ReactHelmet from "../../components/react-helmet/react-helmet";
class ContactUs extends Component {
  static contextType = SpinnerContext;
  errorPopUpStyle = {
    position: "fixed",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "auto",
    color: "#FF5F5F",
    alignItems: "center",
  };
  constructor(props) {
    super(props);
    this.state = {
      errors: [],
      showError: false,
      showErrorPopup: false,
      showSuccessPopup: false,
    };
  }
  componentDidMount() {
    if (!this.props.isFetchingMe) {
      this.props.getUser();
    }
  }
  formSchema = Yup.object().shape({
    name: Yup.string()
      .trim()
      .min(6, "Name should be equal or bigger than 6 symbols")
      .required("Name should be equal or bigger than 6 symbols"),
    email: Yup.string()
      .email("Please enter valid email")
      .required("Please enter valid email"),
    content: Yup.string()
      .trim()
      .min(10, "Message should be equal or bigger than 10 symbols")
      .max(1000, "Message should not be more than 1000 symbols")
      .required("Message is required"),
  });

  contactUsForm = ({ errors, handleSubmit }) => {
    const handleSubmitLocal = (e) => {
      if (!isEmpty(errors)) {
        this.setState({ showErrorPopup: true, errors: errors });
        setTimeout(() => {
          this.setState({ showErrorPopup: false });
        }, 2500);
      }
      handleSubmit(e);
    };
    return (
      <Form className="contact-form" onSubmit={handleSubmit} autoComplete="off">
        <div className="row">
          <div className="col-md-12">
            <div className="form-group">
              <Field
                className="form__field"
                type="text"
                name="name"
                placeholder="Full Name"
              />
            </div>
          </div>
          <div className="col-md-12">
            <div className="form-group">
              <Field
                className="form__field"
                type="text"
                name="email"
                placeholder="Email"
              />
            </div>
          </div>
          <div className="col-md-12">
            <div className="form-group">
              <Field
                component="textarea"
                className="form__field"
                name="content"
                rows={5}
                placeholder="Your Message"
              />
            </div>
          </div>
          <Toast
            onClose={() => this.setState({ showError: false })}
            show={this.state.showError}
            className="list-group-item-warning"
          >
            <Toast.Body>
              <ul>
                {this.state.showError &&
                  this.state.errors?.map(function (item) {
                    return <li key={item}>{item.split(":")[1] || item}</li>;
                  })}
              </ul>
            </Toast.Body>
          </Toast>
          <div className="col-md-12">
            <div className="form-group">
              <button
                className="btn btn-primary radius-20 px-4"
                onClick={(e) => {
                  handleSubmitLocal(e);
                }}
                type="submit"
              >
                <span>Send message</span>
              </button>
            </div>
          </div>
        </div>
      </Form>
    );
  };

  sendContactMessage = (data, resetForm) => {
    this.setState({ errors: [], showError: false });
    this.context.showLoader("Sending message....");
    this.props
      .sendContactMessage(data)
      .then((res) => {
        this.setState({ showSuccessPopup: true });
        setTimeout(() => {
          this.setState({ showSuccessPopup: false });
        }, 2500);
        resetForm({});
      })
      .catch((err) => {
        this.setState({
          errors: err.data ?? ["Failed to send message. Please try later"],
          showError: true,
        });
      })
      .finally(() => {
        this.context.hideLoader();
      });
  };
  render() {
    const { me } = this.props;
    const { showErrorPopup, showSuccessPopup, errors } = this.state;
    return (
      <>
        <ReactHelmet
          title={"Taste Recovery - Contact Us"}
          description={
            "Taste Recovery is a free, nationwide service for finding safe, peer-reviewed sober living homes. Search hundreds of listings now for free."
          }
        />

        <section
          className={`container savehome-wrp setting-list pb-5 pb-lg-0 ${
            this.props.location.pathname === "/settings/contact-support"
              ? "p-0"
              : ""
          }`}
          id="eight"
        >
          {showErrorPopup && (
            <Modal
              centered
              show={true}
              style={this.errorPopUpStyle}
              className="spinner-taste"
              onHide={() => {}}
            >
              <img
                src={require("../../assets/images/cross-cancel.png")}
                width="50"
                heigth="50"
                alt="Error!"
              />
              <span className="loading-msg">
                {errors[Object.keys(errors)[0]]}
              </span>
            </Modal>
          )}
          {showSuccessPopup && (
            <Modal
              centered
              show={true}
              style={this.errorPopUpStyle}
              className="spinner-taste"
              onHide={() => {}}
            >
              <img
                src={require("../../assets/images/check.png")}
                width="50"
                heigth="50"
                alt="Error!"
              />
              <span className="loading-msg">
                Your message was sent sucessfully.
              </span>
            </Modal>
          )}
          <h3 className="main-title title-decoration">Contact Us </h3>
          <img className="img-fluid" src={imgAbout} alt="imgAbout" />
          <div className="saved-home-wrappper mt-4">
            <div className="cms-wrp mt-5">
              <div className="row">
                <div className="col-md-6">
                  <div className="contacts-item d-flex align-items-center">
                    <div className="contacts-img">
                      <img src={imgHome} alt="imgHome" />
                    </div>
                    <div className="contact-details">
                      <p>
                        131 Dartmouth Street <br /> Boston, Massachusetts 02116{" "}
                        <br /> United States
                      </p>
                    </div>
                  </div>
                  <div className="contacts-item d-flex align-items-center">
                    <div className="contacts-img">
                      <img src={imgPhone} alt="imgPhone" />
                    </div>
                    <div className="contact-details">
                      <p>
                        <a href="tel:+1 760 834 5001">+1 760 834 5001</a>
                      </p>
                    </div>
                  </div>
                  <div className="contacts-item d-flex align-items-center">
                    <div className="contacts-img">
                      <img src={imgEmail} alt="imgEmail" />
                    </div>
                    <div className="contact-details">
                      <p>
                        <a href="mailto:contact@tasterecovery.com">
                          contact@tasterecovery.com
                        </a>
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <Formik
                    enableReinitialize
                    validateOnMount={true}
                    initialValues={{
                      name: me?.name ?? "",
                      email: me?.email ?? "",
                      content: "",
                    }}
                    validationSchema={this.formSchema}
                    validator={() => {
                      console.log("in validator");
                    }}
                    onSubmit={(values, { setSubmitting, resetForm }) => {
                      this.sendContactMessage(values, resetForm);
                    }}
                  >
                    {(props) => this.contactUsForm(props)}
                  </Formik>
                </div>
              </div>
            </div>
          </div>
        </section>
      </>
    );
  }
}

export default connect(
  ({ auth }) => ({
    isFetchingMe: auth.isFetchingMe,
    me: auth.me,
    isUpdatingMe: auth.isUpdatingMe,
  }),
  authAction
)(ContactUs);
