import React, { Component } from "react";
import selectTourDates from "../../modules/living/select-tour-dates";
import { Modal } from "react-bootstrap";
import Compressor from 'compressorjs';
import { SpinnerContext } from "../../components/spinner/spinner.component";
import axios from "axios";


class UploadPhotosComponent extends Component {
    static contextType = SpinnerContext;

    upload;
    constructor(props) {
        super(props);
        this.state = {
            dragId:"",
            showErrorPopup:false,
            showOcrErr:false,
            showLoading:false
        };
    }
    
    async onChangeFile(event,selectedPhotos) {
        const {  addImage } = this.props;
        const context=this.context;
        event.stopPropagation();
        event.preventDefault();
        let files = event.target.files;
        let start=selectedPhotos?.length;
        let compressedPhotos=[];
        for (const file of files) {
            const formData = new FormData();
            formData.append('file', file);
            formData.append('apikey', process.env.REACT_APP_OCR_KEY);
      
            try {
              const response = await axios.post('https://api.ocr.space/parse/image', formData, {
                headers: { 'Content-Type': 'multipart/form-data' },
              });
      
              if (response.data.IsErroredOnProcessing) {
                this.setState({ showOcrErr: true});
                     this.errors = {};
                     setTimeout(() => {
                this.setState({ showOcrErr: false });
                }, 2500);
                context.hideLoader("");
                return;
              }
      
              const parsedText = response.data.ParsedResults[0]?.ParsedText?.trim();
              if (parsedText) {
                this.setState({ showOcrErr: true});
                this.errors = {};
                setTimeout(() => {
                this.setState({ showOcrErr: false });
              }, 2500);
                context.hideLoader("");
                return;
              }
            } catch (error) {
              console.error('Error during the OCR process:', error);
            //   setErrorMessage('Error during the OCR process');
            this.setState({ showOcrErr: true});
            this.errors = {};
            setTimeout(() => {
                this.setState({ showOcrErr: false });
            }, 2500);
              context.hideLoader("");
              return;
            }
          }
       if(parseInt(selectedPhotos.length)+parseInt(files.length)<=10){
          Object.keys(files).forEach(function(key) {
            if(files[key]?.size>=2000000){
                context.showLoader('Please wait while compressing...');

                let compressImage=new Compressor(files[key], {
                    quality: 0.4,
                    
                    success: (compressedResult) => {
                      compressedResult.position=start++;
                      compressedResult.id=new Date().valueOf().toString()+start
                      compressedPhotos.push(compressedResult);
                      let arrangeSelectedPhotoPosition=selectedPhotos.map((item,index)=>item.position=index);
                      const finalImages = [...selectedPhotos, ...compressedPhotos];
                      addImage(finalImages);
                      setTimeout(()=>context.hideLoader(),3000)
                    },
                   
                  });     
            }else{
                files[key].position=start++;
                files[key].id=new Date().valueOf().toString()+start;
                //for already uploaded image on server with different position number
                let arrangeSelectedPhotoPosition=selectedPhotos.map((item,index)=>item.position=index);
                const finalImages = [...selectedPhotos, files[key]];
                addImage(finalImages);

            }

           
               
         
                });
      

        }else{
            this.setState({ showErrorPopup: true});
            this.errors = {};
            setTimeout(() => {
                this.setState({ showErrorPopup: false });
            }, 2500);
        }
        if (event.target) {
            event.target.value = "";
        } 
    }

    deleteImage = (index) => {
        // delete here and send updated data to parent component
        // so that parent component will only have to set state/and no delete operation
        const files = [...this.props.selectedPhotos];
        files.splice(index, 1);

        //change position number to display in correct order
        files.map((image,index)=>{
         image.position=index;
        })

        this.props.deleteImage(files);
    }

    handleDrag = (ev) => {
        this.setState({dragId:ev.currentTarget.id});
        
    };


      
   handleDrop = (ev) => {
    const {selectedPhotos}=this.props
    const dragBox = selectedPhotos.find((photo) => photo.id === this.state.dragId);
    const dropBox = selectedPhotos.find((photo) => photo.id === ev.currentTarget.id);

    const dragBoxPostion = dragBox?.position;
    const dropBoxPosition = dropBox?.position;

    const newPhotoState = selectedPhotos.map((photo,index) => {
      if (index==dragBoxPostion) {
        photo.position = dropBoxPosition;
      }
      if (dropBoxPosition<=index&&dragBoxPostion>index) {
        photo.position ++;
      }
      if (dragBoxPostion<index&&dropBoxPosition>=index) {
        photo.position --;
      }
      return photo;
    });
    this.props.addImage(newPhotoState)

  };
  

    render() {
        const { selectedPhotos = [],draggable } = this.props;
        const {showErrorPopup,showOcrErr}=this.state;
        return (
            
            <div className="added-photo-listing">
                 {(showErrorPopup ||showOcrErr) &&
                    <Modal
                        centered
                        show={showErrorPopup ||showOcrErr}
                        style={this.errorPopUpStyle}
                        className="spinner-taste"
                        onHide={() => { }}
                    >
                        <img src={require('../../assets/images/cross-cancel.png')} width="50" heigth="50" alt="Error!" />
                        {showErrorPopup && <span className="loading-msg">{"Can't add more than 10 photos"}</span>}
                       {showOcrErr && <span className="loading-msg">{"Text is not allowed in images. Please upload an image without any text."}</span>}
                    </Modal>
                }
                

                <input id="fileupload"
                    type="file"
                    ref={(ref) => this.upload = ref}
                    style={{ display: 'none' }}
                    onChange={(e)=>this.onChangeFile(e,selectedPhotos)}
                    // multiple="multiple"
                    accept=".jpg,.jpeg,.png"
                />
                <div className="photo-wrp placeholder" onClick={() => this.upload.click()}>
                    <span className="add-pic">
                        <img src={require("../../assets/images/plus.svg")} alt="plus" />
                    </span>
                </div>
       
                {selectedPhotos.sort((a,b)=>a.position-b.position).map((photo, index) => {
                    let reader;
                    if (!photo?.path) {
                        reader = new FileReader()
                        reader.readAsDataURL(photo)
                    }
                    return (
                        <div className="photo-wrp cursor-pointer" key={index} id={photo.id} draggable={draggable}  onDragOver={(ev) => ev.preventDefault()}
                        onDragStart={draggable?this.handleDrag:()=>{return false}}
                        onDrop={this.handleDrop} >
                            <img src={photo?.path ? photo?.thumbnails?photo.thumbnails["thumb720w"]:photo.path : URL.createObjectURL(photo)} alt="NO" />
                           <span className="delete-pic" onClick={() => this.deleteImage(index)}>
                                <img src={require("../../assets/images/ic_close.svg")} alt="ic_close" />
                            </span>
                        </div>  )
                })}
          
            </div>
        );
    }

}

export default UploadPhotosComponent;
