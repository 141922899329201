import React from "react";
import FAQSchemacalifornia from "./FAQSchemacalifornia";

const Californiaheader = () => {
  return (
    <div className="container">
      <FAQSchemacalifornia />
      <div className="faq-content py-0">
        <div className="faq-content-block py-4 mt-2">
          {/* <h1 className="faqs mb-0 text-center"> Facilities For Sober Living in California</h1> */}
          <p>
          If you or a loved one is struggling with substance abuse or addiction, there is good news. Your search for sober living in California is over. 
           By accessing a Taste Recovery center, you’re getting the best care available. <br/>
           Patients can get access to the lifestyle and care they need as they undertake their road to recovery
          </p>
        </div>
      </div>
    </div>
  );
};

export default Californiaheader;
