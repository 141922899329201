import React, { useEffect, useState, useContext } from "react";
import phoneIcon from "../../../assets/images/phone.svg";
import {  useHistory, useParams } from "react-router-dom";
import globeIcon from "../../../assets/images/website.svg";
import messageIcon from "../../../assets/images/message-orange.svg";
import { SpinnerContext } from "../../../components/spinner/spinner.component";
import virtualTourIcon from "../../../assets/images/virtual-tour.svg";
import prequalifyNow from "../../../assets/images/Prequalify now 1.svg";
import btnIcon from "../../../assets/images/arrow-left-orange.svg";
import ParticipatesurevetModal from "../../../components/modal/ParticipatesurevetModal";
import { useSelector, useDispatch } from "react-redux";
import { livingAction, DashboardAction } from "../../../actions";
import { formatPhoneNumber } from "../../../constants/utils";
const ContactInfoCard = ({ data }) => {
  const [showModal, setShowModal] = useState(false);
  const surveyQuestiones = useSelector(
    (state) => state?.survey?.surveyQuestiones
  );
  const comp = useParams();
  const { showLogin } = useContext(SpinnerContext);
  const surveyResponse = useSelector((state) => state?.survey?.surveyResponse);
  const userName = useSelector((state) => state.auth.user);
  const history = useHistory();
  const dispatch = useDispatch();
  const [falg, setflag] = useState(false);
  const [afterlogin, setafterlogin] = useState(false);
  const handleOpenModal = () => {
    if (userName?.name) {
      setShowModal(true);
    } else {
      showLogin(true);
    }
  };

  useEffect(() => {
    if (surveyResponse?.message === "success") {
      setflag(true);
    }
  }, [surveyResponse]);

  useEffect(() => {
    try {
      if (afterlogin && userName?.name) {
        setafterlogin(false);
        FacilitycenterMeassage(data?.id);
      }
    } catch (error) {}
  }, [afterlogin, userName?.name]);

  const FacilitycenterMeassage = async () => {
    if (userName?.name) {
      const datacontact = await dispatch(
        livingAction.contactHomeFacility(data?.id)
      );
      if (datacontact?.id) {
        if (data?.createdBy === userName?.id) {
        } else {
          history.push(`/chats/${datacontact?.id}?contactHome=true`);
        }
      }
    } else {
      showLogin(true);
      setafterlogin(true);
    }
  };

  const handelCreteClick = (data) => {
    let typeOfImpression = [];

    switch (data) {
      case "phone":
        typeOfImpression = ["phone"];
        break;
      case "website":
        typeOfImpression = ["website"];
        break;
      case "facility":
        typeOfImpression = ["facility"];
        break;
      case "chat":
        typeOfImpression = ["chat"];
        break;
      default:
        break;
    }

    dispatch(
      DashboardAction?.CreteClikforDashbaord({
        facilityId: data?.id,
        typeOfImpression: typeOfImpression,
      })
    );
  };

  return (
    <div className="sidebar">
      <div className="card m-0 ">
        <div className="card-header border-0 p-0 bg-transparent">
          <h3>
            Call{" "}
            {data?.name
              ?.split(" ")
              .map(
                (word) =>
                  word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
              )
              .join(" ")}
            {data?.geocodingResult?.cityName
              ? ` - ${data.geocodingResult.cityName}`
              : ""}
            {data?.geocodingResult?.stateShort
              ? `, ${data.geocodingResult.stateShort}`
              : ""}
            {data?.geocodingResult?.country
              ? `, ${data.geocodingResult.country}`
              : ""}
          </h3>
        </div>
        <div className="card-body p-0">
          <p className="mb-0">
            Connect with{" "}
            {data?.name
              ?.split(" ")
              .map(
                (word) =>
                  word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
              )
              .join(" ")}
            {data?.geocodingResult?.cityName
              ? ` - ${data.geocodingResult.cityName}`
              : ""}
            {data?.geocodingResult?.stateShort
              ? `, ${data.geocodingResult.stateShort}`
              : ""}
            {data?.geocodingResult?.country
              ? `, ${data.geocodingResult.country}`
              : ""}{" "}
            by calling their admissions team directly.
          </p>

          <div className="card-actions d-flex flex-column">
            <button
              type="button"
              className="btn btn-primary radius-20 m-0"
              onClick={() => {
                const phoneNumber = data?.phoneNumber;
                if (phoneNumber && data?.createdBy !== userName?.id) {
                  // Trigger phone call only if phoneNumber exists and user is not the creator
                  window.location.href = `tel:${phoneNumber}`;
                }
                handelCreteClick("phone");
              }}
            >
              <span>
                <img src={phoneIcon} className="img-fluid" alt="phoneIcon" />
                {formatPhoneNumber(data?.phoneNumber)}
              </span>
              <img src={btnIcon} className="img-fluid arrow-icon" alt="btnIcon" />
            </button>
            <div className="or-divider">
              <p>OR</p>
            </div>

            <button
              onClick={(e) => {
                e.preventDefault();
                window.open(`${data?.webUrl}`, "_blank");
                handelCreteClick("website");
              }}
              disabled={data?.webUrl === ""}
              data-toggle="tooltip"
              title={data?.webUrl}
              className="btn btn-primary-outline radius-20 m-0"
            >
              <span>
                <img src={globeIcon} className="img-fluid" alt="globeIcon" />
                Open Website
                {/* {data?.url} */}
              </span>
              <img src={btnIcon} className="img-fluid arrow-icon" alt="btnIcon" />
            </button>

            {data?.createdBy !== userName?.id && (
              <button
                type="button"
                className="btn btn-primary-outline radius-20 m-0"
                disabled={data?.createdBy === userName?.id}
                onClick={() => {
                  FacilitycenterMeassage();
                  handelCreteClick("chat");
                }}
              >
                <span>
                  <img src={messageIcon} className="img-fluid" alt="messageIcon" />
                  Message Facility
                </span>
                <img src={btnIcon} className="img-fluid arrow-icon" alt="btnIcon" />
              </button>
            )}
            {data?.createdBy !== userName?.id &&
              surveyQuestiones.length !== 0 &&
              !falg && (
                <button
                  type="button"
                  className="btn btn-primary-outline radius-20 m-0"
                  onClick={handleOpenModal}
                  title={
                    surveyQuestiones.length === 0 &&
                    "Survey questions are not available for this facility"
                  }
                >
                  <span>
                    <img src={prequalifyNow} className="img-fluid" alt="prequalifyNow" />
                    Prequalify now
                  </span>
                  <img src={btnIcon} className="img-fluid arrow-icon" alt="btnIcon" />
                </button>
              )}
            {data?.subscriptionDetail?.subscriptionType === "pro"
              ? data?.virtualHouseLink?.trim() !== "https://www." && (
                  <button
                    onClick={(e) => {
                      e.preventDefault();
                      window.open(`${data?.virtualHouseLink}`, "_blank");
                    }}
                    data-toggle="tooltip"
                    title={data?.virtualHouseLink}
                    className="btn btn-primary-outline radius-20 m-0"
                  >
                    <span>
                      <img src={virtualTourIcon} className="img-fluid" alt="virtualTourIcon" />
                      Virtual Tour
                    </span>
                    <img
                      src={btnIcon}
                      className="img-fluid arrow-icon"
                      alt="btnIcon"
                    />
                  </button>
                )
              : ""}
          </div>
        </div>
      </div>
      <ParticipatesurevetModal
        open={showModal}
        onCancel={() => setShowModal(false)}
        setShowModal={setShowModal}
        id={data?.id}
        webUrl={data?.webUrl}
        phoneNumber={data?.phoneNumber}
      />
    </div>
  );
};

export default ContactInfoCard;
