import React, { useState } from "react";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
const Californiafaq = () => {
  const [expandedIndex, setExpandedIndex] = useState(null);

  const toggleAccordion = (index) => {
    setExpandedIndex(expandedIndex === index ? null : index); // Close if it's already expanded, else open
  };

  const faqData = [
    {
      question: "SOBER LIVING HOUSES IN CALIFORNIA",
      answer: (
        <>
          <p className="mb-2">
          Each of our recovery centers is designed around one thing: reliable care that empowers better and healthier lifestyles. Those seeking refuge and a new start can rely on the Taste Recovery staff. Our team has been trained and certified to provide the best care possible at our California sober living centers.
          <br/>  <br/>
          Browse our sober living homes in California above. We have placed them strategically across the state to ensure convenience. You don’t need to uproot your life to get help. Patients from nearly every corner of California can get access to the substance care they need.
          <br/> <br/>
          As you browse our locations, note the services and treatments available to patients at each location. You’ll notice that each treatment center offers slightly different services to ensure the best for their patients. These services include:
          </p>
          <ul className="list-style pl-4 ml-3 mb-2">
              <li className="mb-1">
              Group therapies,
              </li>
              <li className="mb-1">Addiction treatment services,</li>
              <li className="mb-1">Art and music therapies,</li>
              <li className="mb-1">Peer support groups,</li>
              <li className="mb-1">And more.</li>
            </ul>
            <p className="mb-2">
            We know many patients bounce from one to the next sober living houses in California. Our approach to recovery is built on the ever-changing care you need. As your recovery evolves, we are equipped to evolve with you.
            </p>
          </>
      ),
    },
    {
      question: "SOBER LIVING IN CALIFORNIA LOCATIONS",
      answer: (
        <>
          <p className="mb-2">
          We offer our proven treatment programs and living environments for patients throughout California. Each of our California sober living homes is designed to be comprehensive and reliable. Trust in us as a partner on your road to recovery, even close to home.           <br/>
          <br/>
          We help support many of California’s most populous communities. Consider our sober living facilities in:
          </p>
          <ul className="list-style pl-4 ml-3 mb-2">
              <li className="mb-1">
               Los Angeles,
              </li>
              <li className="mb-1">Newport Beach,</li>
              <li className="mb-1">Orange County,</li>
              <li className="mb-1">And more.</li>
            </ul>
            <p className="mb-2">
             Unlike other rehab programs, we offer holistic, comprehensive, and personalized care to every one of our patients. Whether you struggle with drug addiction, alcohol abuse, or more, trust in Taste Recovery. We are equipped to help you begin your journey toward recovery.
            <br/> <br/>
            Click on any of the sober living homes in California above to learn more about a particular location. When you are ready, feel free to email or call that sober home’s admissions staff. They can offer more information about particular treatments and can set up a tour.
            <br/> <br/>
            We also offer treatment centers for addiction, substance use disorders, mental health disorders, and more throughout the U.S.
            </p>
          </>
      ),
    },
  ];

  return (
    <div className="container" style={{ marginBottom: "200px" }}>
      <div className="faq-content">
        <div className="faq-content-block">
        <h2 class="faqs">Frequently Asked Questions About Sober Living in California</h2>
        </div>
        
        {faqData.map((faq, index) => (
          <div key={index} className="faq-content-block accordion-block">
            <div
              className={`accordion-header ${
                expandedIndex === index ? "expanded" : ""
              }`}
              onClick={() => toggleAccordion(index)}
            >
              <h3>{faq.question}</h3>
              <span className="icon"></span>
            </div>
            {expandedIndex === index && (
              <div className="accordion-content">
                <div className="inner-content">{faq.answer}</div>
              </div>
            )}
          </div>
        ))}
      </div>
      <h5 className="faqs pt-4">
            Get in touch with one of our California sober living homes today.
            </h5>
    </div>
  );
};

export default Californiafaq;
